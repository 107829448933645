import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Modal({
  setIsOpen,
  isOpen,
  children,
  className,
  mainclassName,
  title,
  headingClass,
}: any) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 w-full" onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={`max-w-[550px] w-full mx-auto min-h-full flex items-center justify-center text-center ${mainclassName}`}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all bg-[#161C21] ${className}`}
                >
                  <div className="w-full mb-6 flex items-center justify-between rt-modalheader">
                    <h2
                      className={`text-[30px] text-white font-semibold leading-9 font-gilroy-semibold ${headingClass}`}
                    >
                      {title}
                    </h2>
                    <button className="" type="button" onClick={setIsOpen}>
                      <img
                        src="/images/cross.svg"
                        alt="Cross Icon"
                        width={30}
                        height={30}
                      />
                    </button>
                  </div>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
