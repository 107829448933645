import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

// Define state and action types

// State type for tier state
interface TierState {
  config: {
    collectionTier: any;
    assetTier: any[];
    traitTier: any[];
  };
  slug?: string;
  contractAddress?: string;
  userId?: any; // Replace 'any' with the actual type for userId
}

// State type for collection state
interface CollectionState {
  config: {
    collectionTier: {
      FP: null | any;
      FM: string;
      SET: {
        Price: string;
        Token: string;
      };
      TF: any[];
    };
    assetTier: Array<{
      FP: null | any;
      FM: string;
      SET: {
        Price: string;
        Token: string;
      };
      TF: any[];
      tokenId: string;
    }>;
    traitTier: Array<{
      trait: any[];
      FP: null | any;
      FM: string;
      SET: {
        Price: string;
        Token: string;
      };
      TF: any[];
    }>;
  };
}

// Action types
interface SetTierCollectionDataAction {
  type: "SET_TIER_STATE";
  payload: TierState;
}

interface SetCollectionDataAction {
  type: "SET_COLLECTION_STATE";
  payload: CollectionState;
}
// Additional states
interface SetIsConfigModalAction {
  type: "SET_IS_OPEN";
  payload: boolean;
}

interface SetSelectedCollectionAction {
  type: "SET_SELECTED_COLLECTION";
  payload: any; // Update with the actual type for selectedCollection
}

interface SetTierTabAction {
  type: "SET_TIER_TAB";
  payload: any; // Update with the actual type for tierTab
}

type Action =
  | SetTierCollectionDataAction
  | SetCollectionDataAction
  | SetIsConfigModalAction
  | SetSelectedCollectionAction
  | SetTierTabAction;

// Define context props type
// Define context props type
interface ContextProps {
  TierCollectionData: TierState;
  collectionData: CollectionState;
  IsConfigModal: boolean;
  selectedCollection: any; // Update with the actual type for selectedCollection
  tierTab: any; // Update with the actual type for tierTab
  setTierCollectionData: Dispatch<SetTierCollectionDataAction>;
  setCollectionData: Dispatch<SetCollectionDataAction>;
  setIsConfigModal: Dispatch<SetIsConfigModalAction>;
  setselectedCollection: Dispatch<SetSelectedCollectionAction>;
  setTierTab: Dispatch<SetTierTabAction>;
}

// Define initial states

// Initial state for tier state
const initialTierCollectionData: TierState = {
  config: {
    collectionTier: {},
    assetTier: [],
    traitTier: [],
  },
  slug: "",
  contractAddress: "",
  userId: "",
};

// Initial state for collection state
const initialCollectionData: CollectionState = {
  config: {
    collectionTier: {
      FP: null,
      FM: "",
      SET: {
        Price: "",
        Token: "",
      },
      TF: [],
    },
    assetTier: [
      {
        FP: null,
        FM: "",
        SET: {
          Price: "",
          Token: "",
        },
        TF: [],
        tokenId: "",
      },
    ],
    traitTier: [
      {
        trait: [],
        FP: null,
        FM: "",
        SET: {
          Price: "",
          Token: "",
        },
        TF: [],
      },
    ],
  },
};

// Initial state for additional states
const initialAdditionalState = {
  IsConfigModal: false,
  selectedCollection: null, // Update with the actual initial value/type for selectedCollection
  tierTab: "Collection_Tier", // Update with the actual initial value/type for tierTab
};
// Create the context
const TierCollectionContext = createContext<ContextProps | undefined>(
  undefined
);

// Create the reducer function
const tierCollectionReducer = (
  state: {
    TierCollectionData: TierState;
    collectionData: CollectionState;
    IsConfigModal: boolean;
    selectedCollection: any;
    tierTab: any;
  },
  action: Action
): {
  TierCollectionData: TierState;
  collectionData: CollectionState;
  IsConfigModal: boolean;
  selectedCollection: any;
  tierTab: any;
} => {
  switch (action.type) {
    case "SET_TIER_STATE":
      return { ...state, TierCollectionData: action.payload };
    case "SET_COLLECTION_STATE":
      return { ...state, collectionData: action.payload };
    case "SET_IS_OPEN":
      return { ...state, IsConfigModal: action.payload };
    case "SET_SELECTED_COLLECTION":
      return { ...state, selectedCollection: action.payload };
    case "SET_TIER_TAB":
      return { ...state, tierTab: action.payload };
    default:
      return state;
  }
};

// Create the context provider component
export const TierCollectionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(tierCollectionReducer, {
    TierCollectionData: initialTierCollectionData,
    collectionData: initialCollectionData,
    ...initialAdditionalState,
  });

  // Dispatch functions for setting tier and collection states
  const setTierCollectionData: Dispatch<SetTierCollectionDataAction> = (
    payload: any
  ) => {
    dispatch({ type: "SET_TIER_STATE", payload });
  };

  const setCollectionData: Dispatch<SetCollectionDataAction> = (
    payload: any
  ) => {
    dispatch({ type: "SET_COLLECTION_STATE", payload });
  };

  // Dispatch functions for additional states
  const setIsConfigModal: Dispatch<SetIsConfigModalAction> = (payload: any) => {
    dispatch({ type: "SET_IS_OPEN", payload });
  };

  const setselectedCollection: Dispatch<SetSelectedCollectionAction> = (
    payload: any
  ) => {
    dispatch({ type: "SET_SELECTED_COLLECTION", payload });
  };

  const setTierTab: Dispatch<SetTierTabAction> = (payload: any) => {
    dispatch({ type: "SET_TIER_TAB", payload });
  };
  return (
    <TierCollectionContext.Provider
      value={{
        TierCollectionData: state.TierCollectionData,
        collectionData: state.collectionData,
        IsConfigModal: state.IsConfigModal,
        selectedCollection: state.selectedCollection,
        tierTab: state.tierTab,
        setTierCollectionData,
        setCollectionData,
        setIsConfigModal,
        setselectedCollection,
        setTierTab,
      }}
    >
      {children}
    </TierCollectionContext.Provider>
  );
};

// Create a custom hook to use the context in components
export const useTierCollectionContext = (): ContextProps => {
  const context = useContext(TierCollectionContext);
  if (!context) {
    throw new Error(
      "useTierCollectionContext must be used within a TierCollectionProvider"
    );
  }
  return context;
};
