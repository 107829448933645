import GlobalInput from "../../../../../GlobalInput";
type Props = {
  value: any;
  onChange: any;
  placeholder: any;
};

const FpContent = ({ value, onChange, placeholder }: Props) => {
  return (
    <>
      <form>
        <div className="flex mt-4 relative max-w-[480px]">
          <label className="shrink-0 bg-[#363E45] p-[15px] rounded-[6px_0_0_6px] h-[50px] border border-[#27323B] flex items-center justify-center text-white text-base font-gilroy-medium uppercase">
            FP Value
          </label>
          <GlobalInput
            value={value}
            onchange={onChange}
            placeholder={placeholder}
            disabled={true}
            className="border-l-0 w-full rounded-tl-none rounded-bl-none"
          />
        </div>
      </form>
    </>
  );
};

export default FpContent;
