import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowRight from "../components/icons/ArrowRight";
import InputError from "../components/InputError";
import { useNavigate } from "react-router-dom";
import { routes } from "../router/routes";
import { isUserIdExist, setUserId } from "../utils/userStorage";

type FormData = {
  address: string;
};

export default function Home() {
  const navigate = useNavigate();

  const formik = useFormik<FormData>({
    initialValues: {
      address: "",
    },
    validationSchema: Yup.object().shape({
      address: Yup.string()
        .test("is-valid-wallet-address", "Invalid wallet address", (value) => {
          // Ethereum address regex
          const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

          // Check if the address matches the regex
          return ethereumAddressRegex.test(value || "");
        })
        .required("Wallet address is required"),
    }),
    validateOnBlur: true,
    onSubmit: (values: FormData) => {
      if (!isUserIdExist()) {
        setUserId();
      }
      navigate(`${routes.COLLECTIONS}?address=${values.address}`);
    },
  });

  return (
    <>
      <div className="bg-pattern"></div>
      <div className="container">
        <div className="flex flex-col items-center justify-center text-center h-[calc(100vh-86px)]">
          <h1 className="mb-6 text-white font-andora-modern-serif">
            Market Leading NFT{" "}
            <span className="block">Valuations and Tracking Tools</span>
          </h1>
          <p className="text-lg mb-14">
            Accurate real time insights of your digital assets portfolio with
            user configurable valuation methodologies, <br />
            historical transactions and aggregated data from industry leading
            sources, as used by professional investors.
          </p>
          <form className="w-full max-w-[550px]" onSubmit={formik.handleSubmit}>
            <div className="relative">
              <input
                type="text"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                className="form-control"
                placeholder="Enter your wallet address"
              />
              <button
                type="submit"
                className="bg-[#343D45] rounded-full w-16 h-16 flex items-center justify-center absolute right-3 top-1/2 translate-y-[-50%]"
              >
                <ArrowRight />
              </button>
            </div>
            <InputError formik={formik} name="address" />
          </form>
        </div>
      </div>
    </>
  );
}
