export const SettingDataNft = [
    {
        id: '1',
        title: "OpenSea Link",
        status: true,
    },
    {
        id: '2',
        title: "Asset Image",
        status: true,
    },
    {
        id: '3',
        title: "Asset Name",
        status: true,
    },
    {
        id: '4',
        title: "Quantity",
        status: true,
    },
    {
        id: '5',
        title: "OpenSea Slug",
        status: true,
    },
    {
        id: '6',
        title: "Contract Address",
        status: true,
    },
    {
        id: '7',
        title: "Token ID",
        status: true,
    },
    {
        id: '8',
        title: "Wallet Address",
        status: true,
    },
    {
        id: '9',
        title: "Date Acquired",
        status: false,
    },
    {
        id: '10',
        title: "Config Tier",
        status: false,
    },
    {
        id: '11',
        title: "Method",
        status: false,
    },
    {
        id: '12',
        title: "Trait Floors",
        status: false,
    },
    {
        id: '13',
        title: "Asset Value",
        status: true,
    },
    
]