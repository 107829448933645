import React, { useState } from "react";
import { Button } from "../../../Button";
import { FaTimes } from "react-icons/fa";

export default function BlocksTabs({
  data,
  activeTierBlock,
  handleTierBlock,
  handleRemoveTierBlock,
}: any) {
  return (
    <div className="flex space-x-4">
      {data?.map((item: any, index: number) => (
        <button
          key={index}
          className={`border border-[#6F757B] rounded-[76px] py-2 px-5 text-lg leading-5 font-gilroy-semibold uppercase ${
            activeTierBlock === index
              ? "bg-[rgba(253,161,74,0.14)] text-white !border-orange"
              : "bg-[#1C2329] text-white"
          } flex items-center justify-between`}
        >
          <span onClick={() => handleTierBlock(index)}>Block {index + 1}</span>
          <FaTimes
            className="text-red-500 ml-4 cursor-pointer text-sm"
            onClick={() => handleRemoveTierBlock(index)}
          />
        </button>
      ))}{" "}
    </div>
  );
}
