import { FormikProps } from 'formik';
import React from 'react';

interface IProps {
    formik: FormikProps<any>,
    name: string
}


const InputError = ({formik,name}: IProps) => {
    return (
        <div className="flex items-center mt-2 text-sm">
            {/* <span className="text-red-700">
                <InfoIcon width="16px" height="16px" />
            </span> */}
            <p className="ms-1.5 text-red-700 text-xs">
           { formik && formik.touched[name] && formik.errors[name] && (
            <>{formik.errors[name]}</>
          )}
            </p>
        </div>
    );
};

export default InputError;