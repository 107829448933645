import React, { useEffect, useState } from "react";

import CollectionTier from "../CollectionTier";
import { HttpService } from "../../../../services/base.service";
import { Toast } from "../../../../utils/toast";
import { useTierCollectionContext } from "../../../../context/collectionContext";
import { FiPlusCircle } from "react-icons/fi";
import BlocksTabs from "../Tabs/blockTabs";

type Props = {
  className?: any;
  scrollClass?: any;
};

const httpService = new HttpService();

const AssetTier = ({className, scrollClass}: Props) => {
  const {
    TierCollectionData,
    tierTab: activeTab,
    setIsConfigModal: setIsOpen,
    selectedCollection,
    setselectedCollection,
    setCollectionData,
    collectionData,
  } = useTierCollectionContext();
  const [isLoading, setisLoading]: any = useState(false);

  const [activeTierBlock, setActiveTierBlock]: any = useState(0);

  function handleAddCollection() {
    if (TierCollectionData.config.assetTier.length === 0) {
      return;
    }
    let obj: any = {
      ...collectionData,
      config: {
        ...collectionData.config,
        assetTier: [
          ...collectionData.config.assetTier,
          {
            FP: null,
            FM: "",
            SET: {
              Price: "",
              Token: "",
            },
            TF: [],
            tokenId: "",
          },
        ],
      },
    };
    setCollectionData(obj);
  }
  const handleTierBlock = (tab: number) => {
    setActiveTierBlock(tab);
  };
  useEffect(() => {
    handleTierBlock(0);
  }, [collectionData]);

  const handleRemoveTierBlock = (findIndex: number): void => {
    if (findIndex === 0 && collectionData.config.assetTier.length === 1) {
      return;
    }
    const updatedTrait = [...collectionData.config.assetTier];
    const updatedAssetTrait = [...TierCollectionData.config.assetTier];

    updatedTrait.splice(findIndex, 1);
    updatedAssetTrait.splice(findIndex, 1);
    handleTierBlock(0);

    let obj: any = {
      ...collectionData,
      config: {
        ...collectionData.config,
        assetTier: updatedTrait,
      },
    };
    setCollectionData(obj);
    CallApi({
      ...TierCollectionData,
      config: {
        ...TierCollectionData.config,
        assetTier: updatedAssetTrait,
      },
    });
  };

  function formatCollectionTier(ele: any, tab: string) {
    let collection: any = {};
    switch (tab) {
      case "FP":
        collection["FP"] = ele.FP;
        break;
      case "FM":
        collection["FM"] = ele.FM;
        break;
      case "SET":
        collection["SET"] = ele.SET;
        break;
      case "TF":
        collection["TF"] = ele.TF;
        break;

      default:
        break;
    }
    return collection;
  }

  function CallApi(param: any) {
    httpService
      .put(`/collection/config`, param)
      .then((response: any) => {
        setTimeout(() => {
          setisLoading(false);

          setselectedCollection({
            ...selectedCollection,
            ...response?.payload,
          });

          Toast.fire({
            icon: "success",
            title: "Config Object",
            text: "Config Object is saved successfully",
          });
        }, 1000);
        console.log(response, "res");
      })
      .catch((err) => {
        setTimeout(() => {
          setisLoading(false);
          Toast.fire({
            icon: "error",
            title: "Error",
            text: "Something Went Wrong!",
          });
        }, 1000);
        console.log(err, "res");
      });
  }

  const handleSubmit = ({
    collectionItem: collection,
    item: findIndex,
    pricingTab,
  }: any) => {
    setisLoading(true);
    const updatedTrait = [...collectionData.config.assetTier];
    const updatedAssetTier = [...TierCollectionData.config.assetTier];

    updatedTrait.splice(findIndex, 1, collection);
    let collectionTrait = {
      [collection.tokenId]: formatCollectionTier(collection, pricingTab),
    };
    updatedAssetTier.splice(findIndex, 1, collectionTrait);

    let obj: any = {
      ...collectionData,
      config: {
        ...collectionData.config,
        assetTier: updatedTrait,
      },
    };
    setCollectionData(obj);

    CallApi({
      ...TierCollectionData,
      config: {
        ...TierCollectionData.config,
        assetTier: updatedAssetTier,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col grow">
        <div className="flex gap-3 mb-3">
          <button
            onClick={handleAddCollection}
            className="bg-orange rounded-full w-10 h-10 flex items-center justify-center text-[#292D32] text-xl"
          >
            <FiPlusCircle />
          </button>
          <BlocksTabs
            data={collectionData.config.assetTier}
            activeTierBlock={activeTierBlock}
            handleTierBlock={handleTierBlock}
            handleRemoveTierBlock={handleRemoveTierBlock}
          />
        </div>
        {collectionData.config.assetTier?.map((item: any, index: number) => (
          <div className="mt-4 flex flex-col grow" key={index}>
            {index === activeTierBlock && (
              <CollectionTier
                className={className}
                scrollClass={scrollClass}
                item={index}
                onSaveCollection={handleSubmit}
                selectedCollectionData={item}
                isLoading={isLoading}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default AssetTier;
