import React from "react";
import GlobalSelect from "../../../../../GlobalSelect";
import { HiOutlinePlusSm } from "react-icons/hi";
import { FiMinus } from "react-icons/fi";

type Props = {
  onAdd: any;
  traitOptions: any;
  onRemove: any;
  validateTraitOptions: any;
  handleTFTypeChange: any;
  handleTFValueChange: any;
  selectedCollection: any;
  className?: any;
  hasAddButton?: boolean;
};

const TraitFloorContent = ({
  onAdd,
  onRemove,
  traitOptions,
  validateTraitOptions,
  handleTFTypeChange,
  handleTFValueChange,
  selectedCollection,
  hasAddButton = true
}: Props) => {
  return (
    <>
      <div className={`relative max-w-[780px]`}>
        {hasAddButton && (<button
          onClick={onAdd}
          className="bg-orange w-9 h-9 flex justify-center items-center text-[20px] text-[#161C21] rounded-full absolute top-[85px] sm:top-[55px] lg:top-[24px] right-[-20px] z-[1]"
        >
          <HiOutlinePlusSm />
        </button>)}
        {/* Your dynamic trait options  */}
        {traitOptions.map((option: any, index: number) => (
          <div
            key={index}
            className="border border-[#2B3339] rounded-lg py-4 pl-[14px] pr-[40px] sm:pr-[67px] mb-5 flex flex-col lg:flex-row gap-4 relative"
          >
            <div className="">
              <div className="flex flex-col sm:flex-row relative w-full sm:min-w-[350px]">
                <label className="shrink-0 bg-transparent sm:bg-[#363E45] pb-2 sm:p-[15px] rounded-[6px_0_0_6px] h-auto sm:h-[50px] border-0 sm:border border-[#27323B] flex sm:items-center sm:justify-center text-white text-base font-gilroy-medium uppercase">
                  Trait Type
                </label>
                <GlobalSelect
                  onBlur={validateTraitOptions}
                  value={option.type || ""}
                  onChange={(e: any) => {
                    handleTFTypeChange(index, e);
                  }}
                  globalSelectData={Object.keys(selectedCollection?.traits)}
                  className="sm:!border-l-0 w-full sm:!rounded-tl-none sm:!rounded-bl-none"
                />
              </div>
              <span style={{ color: "red" }}>{option.errors.type}</span>
            </div>
            <div className="">
              <div className="flex flex-col sm:flex-row relative w-full sm:min-w-[350px]">
                <label className="shrink-0 bg-transparent sm:bg-[#363E45] pb-2 sm:p-[15px] rounded-[6px_0_0_6px] h-auto sm:h-[50px] border-0 sm:border border-[#27323B] flex sm:items-center sm:justify-center text-white text-base font-gilroy-medium uppercase">
                  Trait Value
                </label>
                <GlobalSelect
                  onBlur={validateTraitOptions}
                  value={option.value || ""}
                  onChange={(e: any) => handleTFValueChange(index, e)}
                  globalSelectData={
                    option.type &&
                    Object.keys(selectedCollection?.traits[option.type])
                      ? Object.keys(selectedCollection?.traits[option.type])
                      : []
                  }
                  className="sm:!border-l-0 w-full sm:!rounded-tl-none sm:!rounded-bl-none"
                />
              </div>
              <span style={{ color: "red" }}>{option.errors.value}</span>
            </div>

            {index !== 0 && traitOptions?.length > 1 && (
              <button
                onClick={() => onRemove(index)}
                className="border border-Greyyish bg-transparent w-9 h-9 flex justify-center items-center text-Greyyish rounded-full absolute top-1/2 translate-y-[-50%] right-[-20px]"
              >
                <FiMinus className="text-[20px]" />
              </button>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default TraitFloorContent;
