import React from 'react'

interface Ipropse {
    figClass?: any;
    imageSrc: any;
    className?: any;
    contentClass?: any;
    title: any;
    text?: any;
    headingclass?: any;
    textclass?: any;
    imgHeight: any;
    imgWidth: any;
}

const ImageWithText = ({figClass,imageSrc,className,title,text,headingclass,textclass,contentClass,imgHeight,imgWidth}: Ipropse) => {
  return (
    <div className={`flex items-center gap-5 ${className}`}>
        <figure className={`w-11 h-11 rounded-full overflow-hidden flex-shrink-0 ${figClass}`}>
            <img src={imageSrc} alt='description' className='w-full h-full object-cover' width={imgWidth} height={imgHeight} />
        </figure>
        <div className={`flex flex-col ${contentClass}`}>
            <h3 className={`text-base font-gilroy-medium text-white ${headingclass}`}>{title}</h3>
            {text && <span className={`text-sm text-[#A1A1A5] font-gilroy-semibold mt-1 ${textclass}`}>{text}</span>}
        </div>
    </div>
  )
}

export default ImageWithText