import React from 'react';
import Modal from './Modal';
import TransectionHistoryTable from './TransectionHistoryTable';

type Props={
    setIsOpen?:any,
    isOpen?: boolean,
    className?: any,

}

const NftDetailModal = ({
    setIsOpen, isOpen, className
}:Props) => {
    return (
        // <div>
            <Modal className="w-full" title="NFT Details" mainclassName="!max-w-[1500px] w-full" setIsOpen={setIsOpen} isOpen={isOpen}>
                <div className="w-full max-h-[750px] overflow-auto rt-Scrollnone">
                    <div className="w-full flex flex-col lg:flex-row items-start gap-8">
                        <figure className="flex-shrink-0 mx-auto lg:m-0 sm:max-w-[540px] w-full">
                            <img className="w-full" src="/images/nft/nft-detail.jpg" alt="NFT Detail" width={540} height={565}/>
                        </figure>
                        <div className="grow">
                            <div className="flex items-center gap-4 mb-6">
                                <figure className="w-[56px] h-[56px] rounded-full overflow-hidden">
                                    <img className="w-full h-full rounded-full" src="/images/nft/nft-detail.jpg" alt="NFT Detail" width={540} height={565}/>
                                </figure>
                                <div className="">
                                    <h3 className="text-white font-gilroy font-bold text-base">Morpheus Inu</h3>
                                    <span className="block font-gilroy font-semibold text-[#A1A1A5] text-sm">Owner</span>
                                </div>
                            </div>
                            <div className="w-full mb-8">
                                <h4 className="text-white font-gilroy font-extrabold text-[28px] mb-2">Morpheus Inu</h4>
                                <span className="block text-[20px] text-[#A1A1A5]">Price: <strong className="text-[#FDA14A]">0.139 MR</strong></span>
                            </div>
                            <div className="w-full">
                                <ul className="m-0 p-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 list-none gap-5">
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">Blockchain</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">Ethereum</h3>
                                        </div>
                                    </li>
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">NFT Standard</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">ERC1155</h3>
                                        </div>
                                    </li>
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">Creator</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">0x2ab02e13abd4...c91b</h3>
                                        </div>
                                    </li>
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">Minting Hash</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">0x2ab02e13abd4...c91b</h3>
                                        </div>
                                    </li>
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">Minting Date</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">Jun 23, 2022 at 08:43</h3>
                                        </div>
                                    </li>
                                    <li className="list-style-none">
                                        <div className="w-full border border-[#343D45] rounded-2xl min-h-[80px] flex items-start justify-center py-3 px-6 flex-col">
                                            <span className="block text-[#A1A1A5] text-sm">NFT Contract</span>
                                            <h3 className="text-[#FDA14A] w-full font-gilroy-extrabold text-base">0x2ab02e13abd4...c91b</h3>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className="w-full mt-7">
                                <h5 className="text-white text-base font-gilroy font-bold mb-3">NFT Description</h5>
                                <p className="text-[#A7ABAD] text-base leading-7">
                                    Moving replenish fowl night air created greater. Which whose midst darkness greater set moved male green you herb subdue winged moved, life for set have won&lsquo;t shall. Moving. Winged lights fill good that seed thing own kind day was Lesser. It appear fly darkness. Signs his void fill. Every give a every Seas meat. Called of. Gathering creepeth sea.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-10">
                        <h4 className="text-white font-gilroy font-semibold mb-3">Item Activity</h4>
                        <TransectionHistoryTable/>
                    </div>

                </div>
            </Modal>
        // </div>
    );
};

export default NftDetailModal;