// userStorage.ts
import { v4 as uuidv4 } from 'uuid';
function isUserIdExist(): string | any {
    return localStorage.getItem("userId");
  }
  
  function setUserId(): void {
    const Uuid = uuidv4();
    localStorage.setItem("userId", JSON.stringify(Uuid));
  }
  
  export { isUserIdExist, setUserId };