export const formatNumber = (inputNumber: any) => {
    // Check if the input is a valid number
    if (typeof inputNumber !== 'number' || isNaN(inputNumber)) {
        return 'Invalid input. Please provide a valid number.';
    }

    // Check if the number has decimal places
    if (Number.isInteger(inputNumber) || inputNumber % 1 === 0) {
        return inputNumber.toString(); // Return the integer as it is
    } else {
        // Limit the decimal places to 4
        const [integerPart, decimalPart] = inputNumber.toString().split('.');
        const truncatedDecimalPart = decimalPart.slice(0, 4);
        const formattedNumber = `${integerPart}.${truncatedDecimalPart}`;
        return formattedNumber;
    }
}