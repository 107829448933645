import React from 'react'
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className='rt-header'>
        <div className='container'>
            <strong className='rt-logo block w-[166px] h-[80]'>
                <Link to="/" className='block w-full h-full'>
                    <img src="/images/logo.svg" alt='Logo' width={166} height={80} className='block w-full h-full' />
                </Link>
            </strong>
        </div>
    </header>
  )
}

export default Header