import React from "react";
import GlobalInput from "../../../../../GlobalInput";
import GlobalSelect from "../../../../../GlobalSelect";
type Props = {
  priceValue: any;
  tokenValue: any;
  onChange: any;
  placeholder: any;
  onBlur: any;
  priceError: any;
  tokenError: any;
};
const globalSelectData = ["ETH"];
const SetContent = ({
  priceValue,
  tokenValue,
  onChange,
  onBlur,
  placeholder,
  priceError,
  tokenError,
}: Props) => {
  return (
    <>
      <form>
        <div className="flex mt-4 relative max-w-[480px]">
          <label className="shrink-0 bg-[#363E45] p-[15px] rounded-[6px_0_0_6px] h-[50px] border border-[#27323B] flex items-center justify-center text-white text-base font-gilroy-medium uppercase">
            PRICE
          </label>
          <GlobalInput
            value={priceValue}
            onchange={(e: any) => onChange("Price", e.target.value)}
            placeholder={placeholder}
            onBlur={onBlur}
            className="border-l-0 w-full rounded-tl-none rounded-bl-none"
          />
        </div>
        <span style={{ color: "red" }}>{priceError}</span>

        <div className="flex mt-4 relative max-w-[480px]">
          <label className="shrink-0 bg-[#363E45] p-[15px] rounded-[6px_0_0_6px] h-[50px] border border-[#27323B] flex items-center justify-center text-white text-base font-gilroy-medium uppercase">
            TOKEN
          </label>
          <GlobalSelect
            globalSelectData={globalSelectData}
            onChange={(e: any) => onChange("Token", e)}
            value={tokenValue}
            onBlur={onBlur}
            className="!border-l-0 w-full !rounded-tl-none !rounded-bl-none"
          />
        </div>
        <span style={{ color: "red" }}>{tokenError}</span>
      </form>
    </>
  );
};

export default SetContent;
