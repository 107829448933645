import React from 'react'

const ArrowRight = () => {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4 12H17.7146" stroke="white" strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.5 18L19.9999 12L14.5 6" stroke="white" strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </>
  )
}

export default ArrowRight