import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import * as Yup from "yup";

import CollectionTable from "../components/CollectionTable";
import TransactionHistoryModal from "../components/TransactionHistoryModal";
import { nftService } from "../services/nft.service";
import { shortenedWalletAddress } from "../utils/shortenedWalletAddress";
import { Loading } from "../components/Loading";
import { Toast } from "../utils/toast";
import { isUserIdExist, setUserId } from "../utils/userStorage";
import { TierCollectionProvider } from "../context/collectionContext";

export default function Collections() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [collections, setCollections] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [aggrigatedPrices, setAggrigatedPrices] = useState<any>({});
  useEffect(() => {
    if (!isUserIdExist()) {
      setUserId();
    }
  }, []);
  useEffect(() => {
    getWalletAssetsHandler(searchParams.get("address") ?? "");
  }, [setSearchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWalletAssetsHandler = (address: string) => {
    const walletAddressSchema = Yup.string().test(
      "is-valid-wallet-address",
      "Invalid wallet address",
      (value) => {
        // Ethereum address regex
        const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

        // Check if the address matches the regex
        if (!ethereumAddressRegex.test(value || "")) {
          return false;
        }

        // Additional checks, if needed (e.g., checksum validation for Ethereum addresses)

        return true;
      }
    );
    walletAddressSchema
      .validate(address)
      .then(async (valid) => {
        if (valid) {
          let cursor = null;
          const collectionsCopy = [...collections];
          let loading = true;
          const userId = searchParams.get("u") ?? null;
          do {
            const res: any = await nftService.retrieveWalletAssets(
              address,
              JSON.parse(isUserIdExist()),
              cursor,
              userId
            );
            cursor = res.payload?.cursor;

            res.payload!.collections.forEach((collection: any) => {
              let index = collectionsCopy.findIndex(
                (el) => el.contractAddress === collection.contractAddress
              );
              if (index !== -1) {
                collectionsCopy[index].nfts = [
                  ...collectionsCopy[index].nfts,
                  ...collection.nfts,
                ];
              } else {
                collectionsCopy.push(collection);
              }
              setCollections([...collectionsCopy]);
              if (loading) {
                closeLoadingWithDealayHandler();
                loading = false;
                Toast.fire({
                  icon: "info",
                  title: "Syncing Wallet Data...",
                  text: "This may take a moment. Please wait while we update wallet information.",
                });
              }
            });

            if (!cursor) {
              Toast.fire({
                icon: "success",
                title: "Wallet Data Synced Successfully!",
                text: "Wallet information is now up-to-date and ready for use.",
              });
              break;
            }
          } while (cursor);
          setMessage(
            collectionsCopy.length == 0 ? "No NFTs found in this wallet" : ""
          );
        } else {
          setMessage("Invalid address");
        }
        console.log(valid ? "Valid address" : "Invalid address");
      })
      .catch((error) => {
        setMessage(error.message);
        console.error("Validation error:", error.message);
      })
      .finally(() => {
        closeLoadingWithDealayHandler();
        // setMessage("");
      });
  };
  const closeLoadingWithDealayHandler = (delay = 1000) => {
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  };
  const calculateAggrigatedData = () => {
    for (let i = 0; i < collections.length; i++) {
      let collection = collections[i];
      let aggrigatedPrice = 0;
      for (let j = 0; j < collection.nfts.length; j++) {
        let nft = collection.nfts[j];
        let price = nft.price.price;
        aggrigatedPrice = aggrigatedPrice + price;
      }
      setAggrigatedPrices((prices: any) => {
        return {
          ...prices,
          [collection.collection]: aggrigatedPrice,
        };
      });
    }
  };
  useEffect(() => {
    if (collections) {
      calculateAggrigatedData();
    }
  }, [collections]);

  function appendParamsToUrl(key: string, value: string) {
    // Get the current URL
    let currentUrl = window.location.href;

    // Check if the URL already has a query string
    const separator = currentUrl.includes("?") ? "&" : "?";

    // Append the new parameter to the URL
    const newUrl = `${currentUrl}${separator}${encodeURIComponent(
      key
    )}=${encodeURIComponent(value)}`;

    // Set the new URL to window.location.href
    window.location.href = newUrl;
  }

  function removeQueryParameter(url: string, parameterToRemove: string) {
    const urlObject = new URL(url);

    // Get the search parameters
    const searchParams = urlObject.searchParams;

    // Remove the specified query parameter
    searchParams.delete(parameterToRemove);

    // Update the URL object with the modified search parameters
    urlObject.search = searchParams.toString();

    return urlObject.toString();
  }

  function CustomConfiguration() {
    if (!searchParams.get("u")) {
      appendParamsToUrl("u", JSON.parse(isUserIdExist()));
    } else {
      let currentUrl = window.location.href;
      window.location.href = currentUrl;
    }
  }
  function DefaultConfiguration() {
    if (searchParams.get("u")) {
      // Get the current URL
      let currentUrl = window.location.href;
      // // Set the new URL to window.location.href
      window.location.href = removeQueryParameter(currentUrl, "u");
    } else {
      let currentUrl = window.location.href;
      window.location.href = currentUrl;
    }
  }
  console.log("message--->", message);
  console.log("collections.length--->", collections.length);

  if (isLoading) return <Loading />;
  return (
    <>
      {message !== "" || collections.length === 0 ? (
        <div className="AtNoDataFound">
          <div className="flex items-center flex-col">
            <figure className="m-0 mb-8 w-[200px]">
              <img
                className="w-full h-full"
                src="/images/nodata-icon.svg"
                alt="No Data Icon"
              />
            </figure>
            <p className="text-[30px] text-white uppercase font-gilroy-semibold">
              {message}
            </p>
          </div>
        </div>
      ) : (
        <div className="container pt-8 pb-16">
          <div className="flex sm:items-center flex-col sm:flex-row sm:justify-between gap-5 mb-8">
            <div className="flex flex-col">
              <h2 className="text-white font-gilroy-bold text-[32px] leading-9">
                {shortenedWalletAddress(searchParams.get("address") || "")}
              </h2>
              <p className="text-[#ADB6BD] font-gilroy-medium text-base">
                This wallet address contains {collections.length} collections.
              </p>
            </div>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => DefaultConfiguration()}
                className="bg-[#FDA14A] rounded-[28px] text-[#14141F] font-gilroy-semibold text-base min-h-[56px] px-6"
              >
                Use Default Configuration
              </button>

              <button
                type="button"
                onClick={() => CustomConfiguration()}
                className="bg-[#FDA14A] rounded-[28px] text-[#14141F] font-gilroy-semibold text-base min-h-[56px] px-6"
              >
                Use My Custom Configuration
              </button>
            </div>
          </div>
          <TierCollectionProvider>
            <CollectionTable
              collections={collections}
              aggrigatedPrices={aggrigatedPrices}
            />
          </TierCollectionProvider>
        </div>
      )}

      {isOpen && (
        <TransactionHistoryModal
          setIsOpen={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
        />
      )}
    </>
  );
}
