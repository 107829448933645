import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'

const PageLayout = ({children}:any) => {
  return (
    <>
        <div className='rt-wrapper overflow-hidden'>
            <Header />
            <main className="">
              <Outlet />
            </main>
        </div>
    </>
  )
}

export default PageLayout