import React from "react";

export default function Tabs({ activeTab, handleTabs }: any) {
  return (
    <div className="flex flex-col sm:flex-row xl:flex-col">
      <button
        className={`w-full text-left sm:text-center xl:text-left py-[14px] px-5 text-lg text-white focus-visible:outline-none ${
          activeTab === "Collection_Tier"? "bg-[#1C2329]" : "bg-transparent"
        }`}
        onClick={() => handleTabs("Collection_Tier")}
      >
        Collection Tier
      </button>
      <button
        className={`w-full text-left sm:text-center xl:text-left py-[14px] px-5 text-lg text-white focus-visible:outline-none ${
          activeTab === "Trait_Tier" ? "bg-[#1C2329]" : "bg-transparent"
        }`}
        onClick={() => handleTabs("Trait_Tier")}
      >
        Trait Tier
      </button>
      <button
        className={`w-full text-left sm:text-center xl:text-left py-[14px] px-5 text-lg text-white focus-visible:outline-none ${
          activeTab === "Asset_Tier" ? "bg-[#1C2329]" : "bg-transparent"
        }`}
        onClick={() => handleTabs("Asset_Tier")}
      >
        Asset Tier
      </button>
    </div>
  );
}