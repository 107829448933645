// Importing base class
import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class NFTService extends HttpService {
  private readonly prefix: string = "nft";

  retrieveWalletAssets = (
    address: string,
    userConfig: string,
    cursor?: string|null,
    u?:string|null
  ): Promise<IResponseInterface<{ collections: any[], cursor?: string  }>> =>
    this.get(`${this.prefix}/retrieve-wallet-assets/${address}`, {cursor, u, userConfig});
}

export const nftService = new NFTService();
