import React, { useEffect, useState } from "react";
import ImageWithText from "./ImageWithText";
import { Link } from "react-router-dom";
import ChildCollection from "./CollectionDetails/ChildCollection";
import ViewSettingModal from "./ViewSettingModal";
// import EditConfigObjectModal from "./EditConfigObjectModal";
import { SettingData } from "../data/SettingData";
import { SettingDataNft } from "../data/SettingDataNft";
import { Toast } from "../utils/toast";
import ConfigObjectModal from "./ConfigObjectModal/index";
import { isUserIdExist } from "../utils/userStorage";
import { useTierCollectionContext } from "../context/collectionContext";
/**
 * Initial state for the collection configuration, including collectionTier, assetTier, and traitTier.
 * Each tier contains placeholders for FP (Front Page), FM (Featured), SET (Set), and TF (Trait/Feature) data.
 * FP and SET have nested structures with placeholders for Price and Token.
 */

const CollectionTable = ({
  collections,
  aggrigatedPrices,
}: {
  collections: any[];
  aggrigatedPrices: any;
}) => {
  const [collectionSetting, setCollectionSettings] = useState(SettingData);
  console.log("collections", collections);

  const [isVisible, setIsVisible] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const [isEditConfigModalVisibla, setIsEditConfigModalVisibla] = useState(0);

  const {
    TierCollectionData,
    setTierCollectionData,
    collectionData,
    setCollectionData,
    IsConfigModal,
    selectedCollection,
    setIsConfigModal,
    setselectedCollection,
  } = useTierCollectionContext();

  const toggleVisibilityHandler = (i: any) => {
    if (isVisible === i) {
      setIsVisible(-1);
    } else {
      setIsVisible(i);
    }
  };
  const toggleEditConfigModalVisibilityHandler = (i: any) => {
    if (isEditConfigModalVisibla === i) {
      setIsEditConfigModalVisibla(-1);
    } else {
      setIsEditConfigModalVisibla(i);
    }
  };

  const updateSettingDataHandler = (settingId: string, status: boolean) => {
    const settings = [...collectionSetting];
    const settingIndex = settings.findIndex((item) => item.id == settingId);
    if (settingIndex > -1) {
      settings[settingIndex].status = status;
      setCollectionSettings([...settings]);
    }
  };
  const columHideHandler = (settingId: string, html: any) => {
    const settingIndex = collectionSetting.findIndex(
      (item) => item.id == settingId
    );
    if (settingIndex > -1) {
      return collectionSetting[settingIndex].status ? html : null;
    }
    return null;
  };

  useEffect(() => {
    if (selectedCollection) {
      let obj: any = {
        ...TierCollectionData,
        userId: JSON.parse(isUserIdExist()),
        slug: selectedCollection?.collectionId,
        contractAddress: selectedCollection?.contractAddress,
        config: {
          ...TierCollectionData.config,
          collectionTier: selectedCollection?.userConfig?.collectionTier,
          assetTier: selectedCollection?.userConfig?.assetTier,
          traitTier: selectedCollection?.userConfig?.traitTier,
        },
      };
      setTierCollectionData(obj);
    }
  }, [selectedCollection, collectionData, IsConfigModal]);

  /**
   * Generates and returns an assetTier collection based on the selectedCollection's user configuration.
   * Each element in the resulting array represents a transformed assetTier object.
   * @returns {Array} An array of assetTier objects, each with structure { FP, FM, SET: { Price, Token }, TF, tokenId }.
   */
  function setAssetTierCollection() {
    let assetTier = selectedCollection?.userConfig?.assetTier?.map(
      (ele: any) => {
        const keys = Object.keys(ele);
        const tokenId = keys[0];
        const selectedValue = ele[tokenId];
        return {
          ...{
            FP: null,
            FM: "",
            SET: {
              Price: "",
              Token: "",
            },
            TF: [],
            tokenId: "",
          },
          ...selectedValue,
          tokenId: tokenId,
        };
      }
    );
    return assetTier;
  }

  /**
   * Generates and returns a traitTier collection based on the selectedCollection's user configuration.
   * Each element in the resulting array represents a transformed traitTier object.
   * @returns {Array} An array of traitTier objects, each with structure { FP, FM, SET: { Price, Token }, TF }.
   */
  function setTraitTierCollection() {
    let traitTier = selectedCollection?.userConfig?.traitTier?.map(
      (ele: any) => {
        return {
          ...{
            FP: null,
            FM: "",
            SET: {
              Price: "",
              Token: "",
            },
            TF: [],
          },
          ...ele,
        };
      }
    );
    return traitTier;
  }

  /**
   * Effect to update the collection data based on the selectedCollection's user configuration and tierTab.
   * Triggers updates to collectionTier, assetTier, and traitTier in the collectionData state.
   */
  useEffect(() => {
    // Check if selectedCollection has a userConfig
    if (selectedCollection?.userConfig) {
      // Prepare payload for updating collectionData state
      let payload: any = {
        collectionTier: selectedCollection?.userConfig?.collectionTier,
      };

      // Check and update assetTier if available
      if (selectedCollection?.userConfig?.assetTier?.length > 0) {
        payload["assetTier"] = setAssetTierCollection();
      }

      // Check and update traitTier if available
      if (selectedCollection?.userConfig?.traitTier?.length > 0) {
        payload["traitTier"] = setTraitTierCollection();
      }

      let obj: any = {
        ...collectionData,
        config: {
          ...collectionData.config,
          ...payload,
        },
      };
      // Update collectionData state using the previous state
      setCollectionData(obj);
    }
  }, [selectedCollection, IsConfigModal]);

  return (
    <>
      <div className="rt-table-responsive">
        <table className="rt-table">
          <thead>
            <tr>
              {collectionSetting.map(
                (setting) =>
                  setting.status && <th key={setting.id}>{setting.title}</th>
              )}
              {/* <th>Collections</th>
                            <th>Total NFTs</th>
                            <th>Floor Price</th>
                            <th>Top Bid</th>
                            <th>1D Volume</th>
                            <th>7D Volume</th>
                            <th>Holders</th>
                            <th>Supply</th> */}
              <th className="!text-center">
                <svg
                  className="cursor-pointer inline-block"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsOpen(true)}
                >
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="#FDA14A"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                    stroke="#FDA14A"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>
            {collections.map((item: any, i: number) => (
              <React.Fragment key={i}>
                <tr className={"parentTable"}>
                  {columHideHandler(
                    "1",
                    <td>
                      {/* <span
                        onClick={() =>
                          toggleEditConfigModalVisibilityHandler(i)
                        }
                      >
                        Config
                      </span> */}
                      <span className="flex gap-2 items-center cursor-pointer">
                        <div
                          onClick={() => {
                            toggleVisibilityHandler(i);
                          }}
                        >
                          <ImageWithText
                            imgWidth={44}
                            imgHeight={44}
                            imageSrc={item.imageUrl}
                            title={item.collection}
                          />
                        </div>
                        <svg
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://opensea.io/collection/${item.collectionId}`
                            );
                            Toast.fire({
                              icon: "success",
                              title: "Copied to clipboard",
                            });
                          }}
                          className="cursor-pointer"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0033 11.4789L15.8931 10.5891C17.6726 8.80955 17.6785 5.89274 15.8931 4.10729C14.1135 2.32774 11.1967 2.32185 9.41126 4.10729L8.52148 4.99707"
                            stroke="#FDA14A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.99198 8.52707L4.10809 9.41095C2.32265 11.1964 2.32265 14.1073 4.10809 15.8928C5.88765 17.6723 8.80446 17.6782 10.5899 15.8928L11.4738 15.0089"
                            stroke="#FDA14A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.64355 12.3574L12.3576 7.64338"
                            stroke="#FDA14A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {/* {item.childCollectionData &&
                                                <button>
                                                </button>
                                            } */}
                      </span>
                    </td>
                  )}
                  {columHideHandler(
                    "2",
                    <td>
                      <div className="flex flex-col gap-1">
                        {aggrigatedPrices[item.collection]?.toFixed(3) + " "}
                        ETH
                      </div>
                    </td>
                  )}
                  {columHideHandler(
                    "3",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "4",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "5",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "6",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "7",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "8",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "9",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "10",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "11",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "12",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "13",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "14",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "15",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "16",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "17",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "18",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "19",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "20",
                    <td>
                      <div className="flex flex-col gap-1">
                        {(item?.precalculatedStats?.floorPrice0D ?? 0)}{" "}
                        ETH
                      </div>
                    </td>
                  )}
                  {columHideHandler(
                    "21",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler("22", <td>{item.nfts.length}</td>)}
                  {columHideHandler(
                    "23",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  {columHideHandler(
                    "24",
                    <td>
                      <div className="flex flex-col gap-1"></div>
                    </td>
                  )}
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        let isModal: any = true;
                        setIsConfigModal(isModal);
                        // if (!selectedCollection) setselectedCollection(item);
                        setselectedCollection(item);
                      }}
                      className="bg-[transparent] border border-[#FDA14A] rounded-[28px] text-[#FDA14A] font-gilroy-semibold text-base min-h-[40px] px-5"
                    >
                      Edit Config Object
                    </button>
                  </td>
                </tr>
                {/* {isEditConfigModalVisibla == i && (
                  <EditConfigObjectModal
                    isOpen={true}
                    collectionData={item}
                    setIsOpen={() => toggleEditConfigModalVisibilityHandler(i)}
                  />
                )} */}
                {isVisible === i && (
                  <ChildCollection
                    collectionConfig={item.config}
                    precalculatedStats={item.precalculatedStats}
                    nfts={item.nfts}
                    key={i}
                    toggleVisibilityHandler={toggleVisibilityHandler}
                  />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {isOpen && (
        <ViewSettingModal
          settingData={collectionSetting}
          updateSettingData={updateSettingDataHandler}
          setIsOpen={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
        />
      )}

      {/* edit config object for collection */}
      {IsConfigModal && (
        <>
          <ConfigObjectModal />
        </>
      )}
    </>
  );
};

export default CollectionTable;
