import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";
import { Interface } from "readline";

type Props = {
  globalSelectData: any;
  onChange: any;
  value: any;
  onBlur?: any;
  className?: any;
};

const GlobalSelect = ({
  globalSelectData,
  onChange,
  value,
  onBlur,
  className,
}: Props) => {
  return (
    <>
      <Listbox value={value} onChange={onChange}>
        <div className="relative w-full">
          <Listbox.Button
            // onBlur={onBlur}
            className={`relative w-full bg-[#161C21] border border-[#27323B] rounded-md cursor-default py-[14px] pl-5 pr-10 ${className}`}
          >
            <span className="block text-left">
              {value ? value : "Select Option"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="h-5 w-5 text-gray-400" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              style={{ zIndex: 99 }}
              className="absolute mt-1 max-h-60 w-full z-99 overflow-auto rounded-lg bg-[#21272E] py-3 px-0 text-base focus:outline-none rt-customscroll"
            >
              {globalSelectData?.map((item: any, Idx: any) => (
                <Listbox.Option
                  key={Idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-4 text-white ${
                      active ? "bg-[#1C2329]" : ""
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block font-gilroy-medium`}>{item}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
};

export default GlobalSelect;
