import React, { useState } from "react";
import ImageWithText from "../ImageWithText";
import ViewNftSettingModal from "../ViewNftSettingModal";
import NftDetailModal from "../NftDetailModal";

import { useSearchParams } from "react-router-dom";
import { shortenedWalletAddress } from "../../utils/shortenedWalletAddress";
import { SettingDataNft } from "../../data/SettingDataNft";
import { Toast } from "../../utils/toast";
import { formatNumber } from "../../utils/formatNumber";

const ChildCollection = ({
  nfts,
  i,
  toggleVisibilityHandler,
  collectionConfig,
  precalculatedStats,
}: any) => {
  let [searchParams] = useSearchParams();
  const [nftSetting, setNftSettings] = useState(SettingDataNft);
  const [isOpen, setIsOpen] = useState(false);
  const [nftDetailOpen, setnftDetailOpen] = useState(false);

  const updateSettingDataHandler = (settingId: string, status: boolean) => {
    const settings = [...nftSetting];
    const settingIndex = settings.findIndex((item) => item.id == settingId);
    if (settingIndex > -1) {
      settings[settingIndex].status = status;
      setNftSettings([...settings]);
    }
  };
  const columHideHandler = (settingId: string, html: any) => {
    const settingIndex = nftSetting.findIndex((item) => item.id == settingId);
    if (settingIndex > -1) {
      return nftSetting[settingIndex].status ? html : null;
    }
    return null;
  };

  return (
    <>
      <tr className="addedRow">
        <td className="!p-0" colSpan={9}>
          <table className="childTable w-full bg-[#212930]">
            <thead className="bg-[#2a343c]">
              <tr>
                {nftSetting.map(
                  (setting: any) =>
                    setting.status && <th key={setting.id}>{setting.title}</th>
                )}
                {/* 
                  <th>NFTs</th>
                  <th>Standard</th>
                  <th>Wallet Address</th>
                  <th>Token ID</th>
                  <th>Open Sea Slug</th>
                  <th>Contract Address</th>
                  <th>price</th>
                  <th>Action</th> */}
                <th>
                  <span className="flex gap-2 items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => setIsOpen(true)}
                      className="cursor-pointer"
                    >
                      <path
                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                        stroke="#FDA14A"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                        stroke="#FDA14A"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      onClick={() => {
                        toggleVisibilityHandler(i);
                      }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 15L12 8L5 15"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {nfts.map((nft: any, key: any) => (
                <tr key={`${nft.collectionSlug}-${nft.tokenId}-${key}`}>
                  {columHideHandler(
                    "1",
                    <td>
                      <a
                        href={`https://opensea.io/assets/ethereum/${nft.collectionAddress}/${nft.tokenId}`}
                        target="_blank"
                      >
                        <span className="flex gap-2 items-center">
                          <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                          >
                            <circle cx="50" cy="50" fill="#2081e2" r="50" />
                            <g fill="#fff">
                              <path d="M24.668 51.68l.216-.339L37.89 30.993a.445.445 0 0 1 .78.057c2.173 4.87 4.048 10.926 3.17 14.697-.375 1.551-1.402 3.652-2.558 5.594-.15.283-.314.56-.488.827a.442.442 0 0 1-.37.195H25.048c-.36 0-.57-.39-.38-.683z" />
                              <path d="M82.644 55.461v3.22c0 .186-.113.35-.277.422-1.007.432-4.454 2.014-5.887 4.007-3.658 5.09-6.452 12.37-12.699 12.37h-26.06C28.485 75.48 21 67.97 21 58.702v-.297c0-.247.2-.447.447-.447h14.528c.287 0 .498.267.472.55a4.959 4.959 0 0 0 .519 2.789 5.111 5.111 0 0 0 4.582 2.846h7.192v-5.615h-7.11a.458.458 0 0 1-.37-.72c.078-.118.165-.24.257-.38a51.806 51.806 0 0 0 2.59-4.13c.652-1.14 1.284-2.358 1.792-3.58.103-.221.185-.447.267-.668.139-.39.283-.755.386-1.12.102-.308.185-.632.267-.935.241-1.038.344-2.137.344-3.277 0-.447-.02-.915-.062-1.362-.02-.488-.082-.976-.144-1.464a15.176 15.176 0 0 0-.2-1.305 21.99 21.99 0 0 0-.41-1.952l-.057-.246c-.124-.447-.226-.873-.37-1.32a49.81 49.81 0 0 0-1.367-4.048c-.18-.509-.385-.997-.59-1.485-.303-.735-.612-1.402-.894-2.034-.144-.288-.267-.55-.39-.817a28.758 28.758 0 0 0-.427-.894c-.103-.22-.221-.426-.303-.632l-.879-1.623a.286.286 0 0 1 .324-.416l5.497 1.49h.015c.01 0 .016.005.02.005l.725.2.796.226.293.082v-3.267c0-1.577 1.264-2.856 2.825-2.856.781 0 1.49.318 1.999.837.508.52.827 1.228.827 2.02v4.849l.585.164a.468.468 0 0 1 .134.067c.144.108.35.267.611.462.206.164.427.365.694.57.529.427 1.16.976 1.854 1.608.185.16.365.324.53.488a42.349 42.349 0 0 1 2.85 2.887c.268.303.53.611.797.935.267.329.55.652.796.976.324.432.673.879.976 1.346.144.221.308.447.447.668.39.59.734 1.202 1.063 1.813.139.283.283.591.406.894.365.817.652 1.65.837 2.481.057.18.098.375.118.55v.041c.062.247.083.509.103.776a8.298 8.298 0 0 1-.144 2.563 9.34 9.34 0 0 1-.303 1.074c-.123.35-.246.714-.406 1.058a14.347 14.347 0 0 1-1.104 2.096c-.139.246-.303.508-.468.755-.18.262-.364.509-.529.75-.226.308-.467.632-.714.92-.22.303-.447.606-.693.873a33.57 33.57 0 0 1-1.017 1.16c-.206.242-.427.489-.653.71-.22.246-.447.467-.652.673-.344.344-.632.611-.873.832l-.565.519a.461.461 0 0 1-.303.113H54.39v5.615h5.507a5.152 5.152 0 0 0 3.349-1.239c.324-.282 1.736-1.505 3.406-3.349a.427.427 0 0 1 .21-.128l15.211-4.398a.449.449 0 0 1 .57.432z" />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </td>
                  )}
                  {columHideHandler(
                    "2",
                    <td>
                      <ImageWithText
                        imgWidth={44}
                        imgHeight={44}
                        imageSrc={nft.metadata.image}
                        title={""}
                      />
                    </td>
                  )}
                  {columHideHandler(
                    "3",
                    <td className={`!text-left`}>{nft?.metadata?.name}</td>
                  )}
                  {columHideHandler(
                    "4",
                    <td className={`!text-left`}>
                      {nft.contractType == "ERC721" ? 1 : nft.contractType}
                    </td>
                  )}
                  {columHideHandler(
                    "5",
                    <td className={`!text-left`}>{nft.collectionSlug}</td>
                  )}
                  {columHideHandler(
                    "6",
                    <td className={`!text-left`}>
                      {shortenedWalletAddress(nft.collectionAddress)}
                    </td>
                  )}
                  {columHideHandler(
                    "7",
                    <td className={`!text-left`}>{nft.tokenId}</td>
                  )}
                  {columHideHandler(
                    "8",
                    <td
                      className={`${
                        searchParams.get("address")
                          ? "!text-left"
                          : "!text-center"
                      }`}
                    >
                      {searchParams.get("address") ? (
                        <span className="flex gap-2 items-center">
                          {shortenedWalletAddress(
                            searchParams.get("address") || ""
                          )}
                          <svg
                            onClick={() => {
                              navigator.clipboard.writeText(
                                searchParams.get("address") || ""
                              );
                              Toast.fire({
                                icon: "success",
                                title: "Copied to clipboard",
                              });
                            }}
                            className="cursor-pointer"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.0033 11.4789L15.8931 10.5891C17.6726 8.80955 17.6785 5.89274 15.8931 4.10729C14.1135 2.32774 11.1967 2.32185 9.41126 4.10729L8.52148 4.99707"
                              stroke="#FDA14A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.99198 8.52707L4.10809 9.41095C2.32265 11.1964 2.32265 14.1073 4.10809 15.8928C5.88765 17.6723 8.80446 17.6782 10.5899 15.8928L11.4738 15.0089"
                              stroke="#FDA14A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.64355 12.3574L12.3576 7.64338"
                              stroke="#FDA14A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                  {columHideHandler("9", <td className={`!text-left`}></td>)}
                  {columHideHandler(
                    "10",
                    <td className={`!text-left`}>{nft.price.tier}</td>
                  )}
                  {columHideHandler(
                    "11",
                    <td className={`!text-left`}>{nft.price.method}</td>
                  )}
                  {columHideHandler(
                    "12",
                    <td className={`!text-left`}>
                      {nft.price.method == "TF"
                        ? JSON.stringify(nft.price.traits)
                        : "N/A"}
                    </td>
                  )}
                  {columHideHandler(
                    "13",
                    <td className={`!text-left`}>
                      {formatNumber(nft.price.price)} ETH
                    </td>
                  )}

                  <td></td>
                  {/* <td className='!text-center'>
                      <svg className='cursor-pointer inline-block' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setnftDetailOpen(true)}>
                        <g clip-path="url(#clip0_248_2170)">
                          <path d="M9.49566 13.5395C9.0455 13.4657 8.69667 13.645 8.38645 13.9566C6.51272 15.8358 4.63716 17.7127 2.75975 19.5874C2.69048 19.6646 2.62579 19.7459 2.56604 19.8307L2.48469 19.7852V19.5357C2.48469 18.838 2.48469 18.1404 2.48469 17.4427C2.48469 16.692 1.95042 16.1371 1.24381 16.1378C0.537197 16.1384 0.00292969 16.6968 0.00292969 17.4476C0.00292969 19.1963 0.00292969 20.945 0.00292969 22.6937C0.00292969 23.4679 0.528234 23.996 1.29827 23.9973C3.05618 24.0006 4.81387 24.0006 6.57132 23.9973C7.30413 23.9973 7.8577 23.4638 7.86253 22.7675C7.86735 22.0581 7.31102 21.5204 6.56581 21.517C5.85092 21.517 5.13535 21.517 4.42046 21.517C4.35153 21.517 4.27638 21.5073 4.20469 21.5025L4.15574 21.4287C4.24392 21.3754 4.32762 21.315 4.40599 21.2481C6.26593 19.3923 8.12449 17.5349 9.98167 15.6759C10.0627 15.5987 10.1398 15.5175 10.2126 15.4325C10.3442 15.2743 10.4315 15.0839 10.4655 14.8809C10.4995 14.6779 10.4789 14.4695 10.406 14.277C10.3331 14.0845 10.2104 13.9148 10.0505 13.7852C9.89056 13.6556 9.6991 13.5708 9.49566 13.5395Z" fill="white"/>
                          <path d="M14.4962 10.4604C14.9456 10.5356 15.2972 10.3626 15.6081 10.0503C17.4924 8.15953 19.3806 6.27086 21.2728 4.38426C21.3417 4.31533 21.4106 4.25466 21.5161 4.15539C21.5161 4.99092 21.5161 5.7575 21.5161 6.52409C21.5161 7.29068 22.0359 7.86011 22.7508 7.86356C23.4657 7.86701 23.9972 7.30654 23.9979 6.5372C23.9979 4.80548 23.9979 3.07399 23.9979 1.34273C23.9979 0.515476 23.485 0.00533446 22.6591 0.00464508C20.9274 0.00464508 19.1957 0.00464508 17.4639 0.00464508C17.3608 0.0014947 17.2576 0.0084247 17.1558 0.0253298C16.8557 0.0850903 16.5883 0.253604 16.4049 0.498511C16.2215 0.743419 16.1351 1.04745 16.1622 1.35221C16.1893 1.65698 16.328 1.94099 16.5517 2.14969C16.7755 2.3584 17.0684 2.47709 17.3743 2.48296C18.1754 2.49124 18.9764 2.48296 19.8464 2.48296C19.7423 2.59395 19.6817 2.6622 19.6169 2.727L14.0074 8.33715C13.9199 8.42021 13.838 8.50888 13.762 8.60257C13.6388 8.76198 13.5593 8.95066 13.531 9.1501C13.5028 9.34954 13.5268 9.55289 13.6009 9.74023C13.6749 9.92756 13.7964 10.0924 13.9533 10.2187C14.1103 10.345 14.2973 10.4283 14.4962 10.4604Z" fill="white"/>
                          <path d="M0.892512 7.81134C1.07709 7.86696 1.27213 7.8786 1.46202 7.84534C1.65191 7.81209 1.83139 7.73486 1.98608 7.61983C2.14078 7.50479 2.26641 7.35515 2.35292 7.18288C2.43943 7.0106 2.48442 6.82045 2.48429 6.62767C2.49463 5.82041 2.48429 5.01316 2.48429 4.20589L2.55323 4.17005C2.61389 4.24381 2.66835 4.3224 2.73522 4.38927C4.31803 5.97484 5.90199 7.5604 7.4871 9.14597C7.83179 9.49066 8.17303 9.84982 8.53427 10.18C8.87275 10.4916 9.28017 10.5647 9.71241 10.4041C9.91002 10.3378 10.0849 10.2172 10.217 10.056C10.3491 9.89474 10.433 9.69951 10.459 9.49273C10.5335 9.04257 10.3501 8.69375 10.0392 8.38353C8.15488 6.50291 6.27058 4.62044 4.38628 2.73614C4.31734 2.6672 4.25392 2.59826 4.15258 2.48727H4.49727C5.22112 2.48727 5.94496 2.49762 6.66881 2.48245C6.99192 2.47218 7.29805 2.33531 7.52116 2.10136C7.74427 1.86742 7.86648 1.55515 7.86143 1.23192C7.85523 0.574251 7.33061 0.0130982 6.67019 0.00896191C4.84334 -0.0029873 3.01648 -0.0029873 1.18963 0.00896191C0.879323 0.0136828 0.582892 0.138349 0.362482 0.356832C0.142071 0.575314 0.0148001 0.870646 0.00735348 1.1809C-0.00505533 3.01603 -0.00505533 4.85138 0.00735348 6.68696C0.0114897 7.20951 0.394781 7.66588 0.892512 7.81134Z" fill="white"/>
                          <path d="M23.9921 17.3266C23.985 17.0702 23.8971 16.8225 23.741 16.6189C23.5849 16.4152 23.3686 16.2661 23.1228 16.1926C22.939 16.1353 22.7444 16.1217 22.5544 16.1528C22.3645 16.184 22.1844 16.259 22.0285 16.372C21.8727 16.485 21.7454 16.6328 21.6567 16.8037C21.5681 16.9746 21.5205 17.1638 21.5179 17.3563C21.5055 18.1642 21.5179 18.9722 21.5179 19.8442C21.4145 19.7484 21.3573 19.6981 21.3035 19.6436C19.6757 18.0171 18.0485 16.3904 16.422 14.7635C16.1049 14.4471 15.7961 14.1217 15.4652 13.8198C15.3119 13.6715 15.1186 13.5715 14.909 13.532C14.6995 13.4926 14.483 13.5154 14.2863 13.5978C14.0844 13.6655 13.9062 13.7899 13.7731 13.9562C13.64 14.1224 13.5575 14.3235 13.5356 14.5353C13.4749 14.9634 13.6459 15.3005 13.9444 15.5984C15.8425 17.4905 17.738 19.3851 19.6311 21.2823C19.6952 21.3464 19.7538 21.4146 19.8455 21.5146H19.5449C18.8128 21.5146 18.08 21.5063 17.3479 21.518C17.0251 21.5247 16.7179 21.6576 16.492 21.8881C16.2661 22.1187 16.1395 22.4286 16.1394 22.7513C16.1394 23.4193 16.6668 23.9881 17.3396 23.9922C19.1582 24.0033 20.9765 24.0033 22.7946 23.9922C23.1095 23.9903 23.4111 23.8652 23.6349 23.6437C23.8586 23.4223 23.9869 23.122 23.9921 22.8072C24.0045 20.9803 24.0017 19.1535 23.9921 17.3266Z" fill="white"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_248_2170"><rect width="24.0014" height="24" fill="white"/></clipPath>
                        </defs>
                      </svg>
                    </td> */}
                  {/* <td className='!text-center'>&nbsp;</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>

      {isOpen && (
        <ViewNftSettingModal
          updateSettingData={updateSettingDataHandler}
          nftSetting={nftSetting}
          setIsOpen={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
        />
      )}
      {nftDetailOpen && (
        <NftDetailModal
          setIsOpen={() => {
            setnftDetailOpen(false);
          }}
          isOpen={nftDetailOpen}
        />
      )}
    </>
  );
};

export default ChildCollection;
