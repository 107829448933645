import React from "react";

export default function PricingTabs({ activeTab, handleTabs }: any) {
  return (
    <>
      <button
        className={`border border-[#8C99A3] rounded-md py-2 px-8 text-lg leading-5 font-gilroy-medium uppercase ${
          activeTab === "FP" ? "bg-orange text-[#161C21] border-orange" : "bg-transparent text-[#8C99A3]"
        }`}
        onClick={() => handleTabs("FP")}
      >
        FP
      </button>
      <button
        className={`border border-[#8C99A3] rounded-md py-2 px-8 text-lg leading-5 font-gilroy-medium uppercase ${
          activeTab === "FM" ? "bg-orange text-[#161C21] border-orange" : "bg-transparent text-[#8C99A3]"
        }`}
        onClick={() => handleTabs("FM")}
      >
        FM
      </button>
      <button
        className={`border border-[#8C99A3] rounded-md py-2 px-8 text-lg leading-5 font-gilroy-medium uppercase ${
          activeTab === "SET" ? "bg-orange text-[#161C21] border-orange" : "bg-transparent text-[#8C99A3]"
        }`}
        onClick={() => handleTabs("SET")}
      >
        SET
      </button>
      <button
        className={`border border-[#8C99A3] rounded-md py-2 px-8 text-lg leading-5 font-gilroy-medium uppercase ${
          activeTab === "TF" ? "bg-orange text-[#161C21] border-orange" : "bg-transparent text-[#8C99A3]"
        }`}
        onClick={() => handleTabs("TF")}
      >
        Trait Floor
      </button>
    </>
  );
}
