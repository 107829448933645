// import React, { useReducer, useState } from "react";
import PricingTabs from "../Tabs/PricingTabs";
import FpContent from "./Components/FpContent";
import FmContent from "./Components/FmContent";
import SetContent from "./Components/SetContent";
import TraitFloorContent from "./Components/TraitFloorContent";
import { useCollectionTier } from "./CollectionTierHook";
import GlobalInput from "../../../GlobalInput";
import { Button } from "../../../Button";
import React, { useEffect, useState } from "react";
import { HttpService } from "../../../../services/base.service";
import { Toast } from "../../../../utils/toast";
import { useTierCollectionContext } from "../../../../context/collectionContext";
const httpService = new HttpService();
type Props = {
  className?: any;
  scrollClass?: any;
  onSaveCollection?: any;
  selectedCollectionData?: any;
  item?: any;
  isLoading?: boolean;
};
const CollectionTier = ({
  onSaveCollection,
  item,
  selectedCollectionData,
  isLoading,
  className,
  scrollClass,
}: Props) => {
  const {
    TierCollectionData,
    setTierCollectionData,
    tierTab: activeTab,
    setIsConfigModal,
    IsConfigModal,
    selectedCollection,
    setselectedCollection,
  } = useTierCollectionContext();
  const {
    state,
    pricingTab,
    handlePricingTabs,
    handleCollectionChange,
    validateCollectionTier,
    validateTraitOptions,
    handleTFTypeChange,
    handleCollectionSetChange,
    handleRemoveTraitOptions,
    onAddTraitOptions,
    handleTFValueChange,
    handleTokenIdChange,
    hasRequiredError,
    validateTokenId,
    dispatch,
    // traits
    onAddTraits,
    handleRemoveTraits,
    handleTraitsTypeChange,
    handleTraitsValueChange,
    validateTraits,
    handleResetCollectionTier,
    // Add other functions and state variables from the hook
  } = useCollectionTier({ activeTab: activeTab });
  const [isSubmitting, setisSubmitting]: any = useState(false);

  /**
   * Updates the collection tier in the state based on the active tab and TierCollectionData.
   * If the active tab is "Collection_Tier," dispatches an action to update the collection tier.
   */

  function setCollectionTier() {
    // Check if the active tab is "Collection_Tier"
    if (activeTab === "Collection_Tier") {
      // Dispatch an action to update the collection tier using the TierCollectionData
      if (selectedCollection?.userConfig?.collectionTier?.TF) {
        setTFCollection(selectedCollection?.userConfig?.collectionTier);
      }
      let payload = {
        ...state.collectionTier,
        ...selectedCollection?.userConfig?.collectionTier,
      };

      dispatch({
        type: "UPDATE_COLLECTION_TIER",
        payload: payload,
      });
    }
  }

  /**
   * Effect to trigger the setCollectionTier function when TierCollectionData, dispatch, or activeTab changes.
   */
  useEffect(() => {
    // Call setCollectionTier to update the collection tier in the state
    setCollectionTier();
  }, []);

  useEffect(() => {
    if (activeTab === "Collection_Tier") {
      let collectionObj: any = TierCollectionData.config.collectionTier;
      let keys = Object.keys(collectionObj);
      if (keys.length > 0) {
        handlePricingTabs(keys[0]);
      }
    }
  }, [TierCollectionData]);

  useEffect(() => {
    if (activeTab !== "Collection_Tier") {
      let collectionObj: any = {
        FP: selectedCollectionData?.FP,
        FM: selectedCollectionData?.FM,
        SET: selectedCollectionData?.SET,
        TF: selectedCollectionData?.TF,
      };
      const findActiveTab = () => {
        for (const key in collectionObj) {
          if (
            pricingTab !== "TF" &&
            collectionObj[key] &&
            (typeof collectionObj[key] !== "object" ||
              Object.values(collectionObj[key]).some(Boolean))
          ) {
            handlePricingTabs(key);
            return;
          } else if (key === "TF" && collectionObj[key].length > 0) {
            handlePricingTabs(key);
            return;
          }
        }
        // handlePricingTabs(pricingTab);
      };

      findActiveTab();
    }
  }, []);

  /**
   * Updates the asset tier in the state based on the active tab, selectedCollectionData, and dispatch.
   * If the active tab is "Asset_Tier," dispatches actions to update the tokenId and collection tier.
   */
  function setAssetTier() {
    // Check if the active tab is "Asset_Tier"
    if (activeTab === "Asset_Tier") {
      // Create a temporary copy of selectedCollectionData
      let tempItem = { ...selectedCollectionData };

      // Dispatch an action to update the tokenId in the state
      dispatch({
        type: "UPDATE_TOKEN_ID",
        payload: tempItem?.tokenId,
      });

      // Remove tokenId from the temporary copy
      delete tempItem.tokenId;

      // Dispatch an action to update the collection tier in the state
      dispatch({
        type: "UPDATE_COLLECTION_TIER",
        payload: tempItem,
      });
    }
  }

  /**
   * Effect to trigger the setAssetTier function when selectedCollectionData, dispatch, or activeTab changes.
   */
  useEffect(() => {
    // Call setAssetTier to update the asset tier in the state
    setAssetTier();
  }, []);

  /**
   * Maps TF (Trait/Feature) data and dispatches an action to update trait options in the state.
   */

  function setTFCollection(param: any) {
    if (param?.TF?.length > 0) {
      let payload = param?.TF?.map((ele: any) => {
        const keys = Object.keys(ele);
        const values = Object.values(ele);
        const selectedValue = values[0];
        const selectedKey = keys[0];
        return {
          type: selectedKey,
          value: selectedValue,
          errors: { type: "", value: "" },
        };
      });

      dispatch({
        type: "UPDATE_TRAIT_OPTIONS",
        payload,
      });
    }
  }

  /**
   * Maps trait data and dispatches an action to update traits in the state.
   */
  function setCollectionTrait() {
    if (selectedCollectionData?.trait?.length > 0) {
      let payload = selectedCollectionData?.trait?.map((ele: any) => {
        const keys = Object.keys(ele);
        const values = Object.values(ele);
        const selectedValue = values[0];
        const selectedKey = keys[0];
        return {
          type: selectedKey,
          value: selectedValue,
          errors: { type: "", value: "" },
        };
      });

      dispatch({
        type: "UPDATE_TRAITS",
        payload,
      });
    }
  }

  /**
   * Effect to update trait options, traits, and the overall collection tier in the state based on selectedCollectionData.
   */
  useEffect(() => {
    // Check if selectedCollectionData is available
    if (selectedCollectionData) {
      // Call setTFCollection to update trait options
      setTFCollection(selectedCollectionData);

      // Call setCollectionTrait to update traits
      setCollectionTrait();

      // Dispatch an action to update the overall collection tier in the state
      dispatch({
        type: "UPDATE_COLLECTION_TIER",
        payload: {
          ...state.collectionTier,
          ...selectedCollectionData,
        },
      });
    }
  }, []);

  /**
   * Validates the collection data based on the current pricing tab.
   * If the pricing tab is "TF" (Trait/Feature), invokes the function to validate trait options.
   * Returns a boolean indicating whether the collection data has any required errors.
   * @returns {boolean} - True if the collection data has required errors, otherwise false.
   */
  function validateCollection() {
    let hasRequired = false;

    // Validate collection tier and check for required errors
    hasRequired = hasRequiredError(validateCollectionTier());

    // If the pricing tab is "TF", validate trait options
    if (pricingTab === "TF") {
      hasRequired = validateTraitOptions();
    }

    return hasRequired;
  }

  /**
   * Formats the collection tier data based on the provided tab, preparing it for submission.
   * @param {string} tab - The active pricing tab ("FP", "FM", "SET", or "TF").
   * @returns {object} - Formatted collection tier data specific to the active tab.
   */
  function formatCollectionTier(tab: string) {
    let collection: any = {
      collectionTier: {},
    };

    switch (tab) {
      case "FP":
        collection.collectionTier["FP"] = state.collectionTier?.FP;
        break;
      case "FM":
        collection.collectionTier["FM"] = state.collectionTier?.FM;
        break;
      case "SET":
        collection.collectionTier["SET"] = state.collectionTier?.SET;
        break;
      case "TF":
        collection.collectionTier["TF"] = state.traitOptions?.map((trait) => {
          return { [trait.type]: trait.value };
        });
        break;
      default:
        break;
    }

    return collection;
  }

  /**
   * Handles the submission of the collection tier data.
   * Sets the submitting state to true, formats the collection data, and sends a PUT request to update the configuration.
   */
  function onCollectionTierSubmit() {
    setisSubmitting(true);
    // Format the collection tier data based on the active pricing tab
    let collection: any = formatCollectionTier(pricingTab);

    let obj: any = {
      ...TierCollectionData,
      config: {
        ...TierCollectionData.config,
        ...collection,
      },
    };
    setTierCollectionData(obj);
    // Send a PUT request to update the configuration
    httpService
      .put(`/collection/config`, obj)
      .then((response: any) => {
        setTimeout(() => {
          setisSubmitting(false);
          handleResetCollectionTier(pricingTab);
          setselectedCollection({
            ...selectedCollection,
            ...response?.payload,
          });
          // setTierCollectionData((prevState: any) => ({
          //   ...prevState,
          //   config: {
          //     ...prevState.config,
          //     collectionTier: response?.payload?.userConfig?.collectionTier,
          //   },
          // }));
          Toast.fire({
            icon: "success",
            title: "Config Object",
            text: "Config Object is saved successfully",
          });
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setisSubmitting(false);
          Toast.fire({
            icon: "error",
            title: "Error",
            text: "Something Went Wrong!",
          });
        }, 1000);
        console.log(err, "res");
      });
  }

  /**
   * Handles the submission process by performing validation on the collection tier
   * and trait options, and resets the collection tier state to default values.
   * Invokes functions for validating the collection tier and trait options
   * before resetting the state.
   *
   * @returns {void}
   */
  function handleSubmit(): void {
    let isRequired = false;

    // Validate required fields based on the active tab
    if (activeTab === "Asset_Tier") {
      isRequired = hasRequiredError(validateTokenId());
    }
    if (activeTab === "Trait_Tier") {
      isRequired = validateTraits();
    }

    let isCheck = validateCollection();

    // If there are validation errors, return early
    if (isCheck) {
      return;
    }

    // If there are required field errors, return early
    if (isRequired) {
      return;
    }

    // Handle submission based on the active tab
    if (activeTab === "Collection_Tier") {
      onCollectionTierSubmit();
      return;
    }

    // Handle submission for Asset_Tier or Trait_Tier
    let collectionItem: any = {
      ...state?.collectionTier,
    };

    // Update collectionItem based on the active tab
    if (activeTab === "Asset_Tier") {
      collectionItem["tokenId"] = state?.tokenId;
    }

    // Update TF field if present in state
    if (state.traitOptions) {
      collectionItem["TF"] = state.traitOptions.map((trait) => {
        return { [trait.type]: trait.value };
      });
    }

    // Update trait field if present in state
    if (state.traits) {
      collectionItem["trait"] = state.traits.map((trait: any) => {
        return { [trait.type]: trait.value };
      });
    }
    handleResetCollectionTier(pricingTab);

    // Trigger onSaveCollection with the prepared data
    onSaveCollection({ collectionItem, item, pricingTab });
  }
  return (
    <>
    <div className={`overflow-auto rt-customscroll mb-2 ${scrollClass}`}>
      {activeTab === "Asset_Tier" && (
        <div className="max-w-[480px] mb-7">
          <h3 className="text-base font-gilroy-bold mb-4 uppercase">
            Enter token id{" "}
          </h3>
          <GlobalInput
            value={state.tokenId}
            onBlur={validateCollectionTier}
            onchange={(e: any) => handleTokenIdChange(e.target.value)}
            placeholder={"Enter token ID"}
            className="w-full"
          />
          <span style={{ color: "red" }}>
            {state?.collectionTierError?.tokenId}
          </span>
        </div>
      )}
      {activeTab === "Trait_Tier" && (
        <div
          className={`pr-5 sm:pr-8 lg:pr-0`}
        >
          <TraitFloorContent
            traitOptions={state.traits}
            onRemove={handleRemoveTraits}
            onAdd={onAddTraits}
            validateTraitOptions={validateTraits}
            handleTFTypeChange={handleTraitsTypeChange}
            handleTFValueChange={handleTraitsValueChange}
            selectedCollection={selectedCollection}
          />
        </div>
      )}

      <div className="flex flex-col mt-4 grow">
        <h3 className="text-base font-gilroy-bold mb-4 uppercase">
          Select pricing methodology
        </h3>
        <div className="flex flex-wrap sm:flex-nowrap gap-4">
          {/* Horizontal Tabs */}
          <PricingTabs handleTabs={handlePricingTabs} activeTab={pricingTab} />
        </div>

        {/* Content based on Main Tab */}
        <div className={`mt-7`}>
          {pricingTab === "FP" && (
            <FpContent
              placeholder="null"
              value={state.collectionTier.FP}
              onChange={(e: any) =>
                handleCollectionChange("FP", e.target.value)
              }
            />
          )}

          {pricingTab === "FM" && (
            <FmContent
              error={state.collectionTierError.FM}
              onBlur={validateCollectionTier}
              placeholder="Enter Value"
              value={state.collectionTier.FM}
              onChange={(e: any) => {
                const re = /^\d*\.?\d*$/;
                // if value is not blank, then test the regex
                if (e.target.value === "" || re.test(e.target.value)) {
                  handleCollectionChange("FM", e.target.value);
                }
              }}
            />
          )}

          {pricingTab === "SET" && (
            <SetContent
              priceError={state.collectionTierError?.SET?.Price}
              tokenError={state.collectionTierError?.SET?.Token}
              placeholder="Enter Value"
              onBlur={validateCollectionTier}
              priceValue={state.collectionTier?.SET?.Price}
              tokenValue={state.collectionTier?.SET?.Token}
              onChange={handleCollectionSetChange}
            />
          )}
          {pricingTab === "TF" && (
            <div className={` pr-5 sm:pr-8 lg:pr-0 ${className}`}>
              <TraitFloorContent
                traitOptions={state?.traitOptions}
                onRemove={handleRemoveTraitOptions}
                onAdd={onAddTraitOptions}
                validateTraitOptions={validateTraitOptions}
                handleTFTypeChange={handleTFTypeChange}
                handleTFValueChange={handleTFValueChange}
                selectedCollection={selectedCollection}
                hasAddButton={activeTab != "Collection_Tier"} 
              />
            </div>
          )}
        </div>
      </div>
    </div>
      {/* <div className="container mx-auto mt-8">
        <pre>{JSON.stringify(state, null, 2)}</pre>-{" "}
      </div> */}
      <div className="flex justify-end pt-6 gap-4 flex-wrap sm:flex-nowrap border-t border-[#27323B]">
        <Button
          onClick={() => {
            let isModal: any = !IsConfigModal;
            setIsConfigModal(isModal);
          }}
          variant="outline"
          className="min-w-[228px] uppercase w-full sm:w-auto"
        >
          Cancel
        </Button>
        {activeTab === "Collection_Tier" ? (
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="min-w-[228px] uppercase w-full sm:w-auto"
          >
            {isSubmitting ? "Saving...." : "Save Object"}
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            className="min-w-[228px] uppercase w-full sm:w-auto"
          >
            {isLoading ? "Saving...." : "Save Object"}
          </Button>
        )}
      </div>
    </>
  );
};

export default React.memo(CollectionTier);
