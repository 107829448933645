export const TransactionHistoryData = [
    {
        method: '290 days 10 Hours ago',
        txhash: '0x2c...89s09',
        value: '24',
        gas: '0.002906',
        transferfrom: '0xdd...ab60',
        transferto: '0xfb...8eb0',
    },
    {
        method: '290 days 10 Hours ago',
        txhash: '0x2c...89s09',
        value: '18',
        gas: '0.002906',
        transferfrom: '0xdd...ab60',
        transferto: '0xfb...8eb0',
    },
    {
        method: '290 days 10 Hours ago',
        txhash: '0x2c...89s09',
        value: '16',
        gas: '0.002906',
        transferfrom: '0xdd...ab60',
        transferto: '0xfb...8eb0',
    },
    {
        method: '290 days 10 Hours ago',
        txhash: '0x2c...89s09',
        value: '10',
        gas: '0.002906',
        transferfrom: '0xdd...ab60',
        transferto: '0xfb...8eb0',
    },
]