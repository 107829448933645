import React from "react";

type Props = {
  value: any;
  onchange: any;
  placeholder: any;
  className?: any;
  onBlur?: any;
  disabled?: boolean;
};

const GlobalInput = (props: Props) => {
  const { placeholder, value, onchange, className, onBlur, disabled } = props;
  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        value={value ?? ""}
        onChange={onchange}
        className={`text-base font-gilroy-medium bg-transparent text-white placeholder:text-[#8C99A3] border border-[#27323B] py-[15px] px-5 rounded-md h-[50px] focus-visible:outline-none ${className}`}
        onBlur={onBlur}
        disabled={disabled ? disabled : false}
      />
    </>
  );
};

export default GlobalInput;
