import React from 'react';
import Modal from './Modal';


type Props={
    setIsOpen?:any,
    isOpen?: boolean,
    className?: any,
    settingData?: any,
    updateSettingData?: any
}

const ViewSettingModal = ({
    setIsOpen, isOpen, className,
    settingData,
    updateSettingData
}:Props) => {
    return (
        // <div>
            <Modal className="w-full" title="View Settings" setIsOpen={setIsOpen} isOpen={isOpen}>
                <ul className="p-0 m-0 w-full list-none max-h-[500px] overflow-auto rt-Scrollnone">
                    {settingData.map((item: any, i:number) => (
                        <li key={i} className={`w-full list-none py-3 ${i < settingData.length - 1 ? 'border-b border-[#22282E]': ''}`}>
                            <div className="flex items-center justify-between">
                                <h2 className="text-white text-base font-gilroy-medium">{item.title}</h2>
                                <div className="AtThemeCheckbox">
                                    <input type="checkbox" name="setting" id={item.id} checked={item.status} onChange={(e) => updateSettingData(item.id, e.target.checked)}/>
                                    <label htmlFor={item.id} className="cursor-pointer"></label>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </Modal>
        // </div>
    );
};

export default ViewSettingModal;