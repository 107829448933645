import { useEffect, useState } from "react";
import Modal from "../Modal";
import Tabs from "./components/Tabs";
import CollectionTier from "./components/CollectionTier";
import AssetTier from "./components/AssetTier";

import TraitTier from "./components/TraitTier";
import { useTierCollectionContext } from "../../context/collectionContext";

const ConfigObjectModal = () => {
  const { IsConfigModal, setIsConfigModal, tierTab, setTierTab } =
    useTierCollectionContext();

  function handleTierTabs(tab: any): void {
    setTierTab(tab);
  }

  return (
    <Modal
      className="w-full !p-0 [&_.rt-modalheader]:bg-[#222A31] [&_.rt-modalheader]:border-b [&_.rt-modalheader]:border-[#2B3339] [&_.rt-modalheader]:p-[20px_45px_20px_32px] [&_.rt-modalheader]:mb-0"
      headingClass="!text-2xl"
      title="Config Object"
      mainclassName="!max-w-[1350px] w-full"
      setIsOpen={() => {
        let isModal: any = !IsConfigModal;
        setIsConfigModal(isModal);
      }}
      isOpen={IsConfigModal}
    >
      <div className="w-full p-[32px]">
        <div className="w-full flex flex-col">
          <div className="flex items-start flex-col xl:flex-row gap-7 2xl:gap-20 flex-grow">
            {/* Left Panel with Tabs */}
            <div className="bg-[#161C21] border border-[#2B3339] rounded-[16px] min-w-[330px] w-full xl:w-auto overflow-hidden shrink-0">
              <h3 className="text-Greyyish p-5 text-xl font-gilroy-semibold">
                Select Tier
              </h3>
              <Tabs handleTabs={handleTierTabs} activeTab={tierTab} />
            </div>

            {/* Main Content Area with Tabs */}
            <div className="flex flex-col w-full min-h-[720px] max-h-[720px] overflow-auto rt-Scrollnone">
              {tierTab === "Collection_Tier" && <CollectionTier scrollClass="min-h-[360px] max-h-[calc(100vh-360px)] lg:min-h-[630px] lg:max-h-[calc(100vh-480px)]"/>}
              {tierTab === "Trait_Tier" && <TraitTier scrollClass="max-h-[calc(100vh-650px)] min-h-[650px] lg:max-h-[calc(100vh-740px)] lg:min-h-[550px]" />}
              {tierTab === "Asset_Tier" && <AssetTier scrollClass="max-h-[calc(100vh-540px)] min-h-[540px] lg:max-h-[calc(100vh-680px)] lg:min-h-[550px]" />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigObjectModal;