import React from 'react';
import { TransactionHistoryData } from '../data/TransactionHistoryData';

const TransectionHistoryTable = () => {
    return (
        <div className='rt-table-responsive rt-transection-table'>
                <table className='rt-table'>
                    <thead>
                        <tr>
                            <th>Method</th>
                            <th>TxHash</th>
                            <th>Value</th>
                            <th>Gas</th>
                            <th>Transfer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TransactionHistoryData.map((item:any, i:any) => (
                            <div  key={i}>
                                <tr>
                                    <td>
                                        <div className="flex flex-col">
                                            <span className="block text-[#FDA14A] text-base font-gilroy-medium">Transfer</span>
                                            <h5 className="text-base font-gilroy-medium text-white">290 days 10 Hours ago</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="block text-[#FDA14A] text-base font-gilroy-medium">{item.txhash}</span>
                                    </td>
                                    <td>
                                        <span className='flex gap-2 items-center'>
                                            <img src="/images/ethicon.svg" alt="ETH Icon" width={20} height={20}/>
                                            {item.value}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='flex gap-2 items-center'>
                                            <img src="/images/ethicon.svg" alt="ETH Icon" width={20} height={20}/>
                                            {item.gas}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="">
                                            <ul>
                                                <li>
                                                    <span className="text-base font-gilroy-medium text-white">From: </span><em className="text-[#FDA14A] text-base font-gilroy-medium not-italic">{item.transferfrom}</em>
                                                </li>
                                                <li>
                                                    <span className="text-base font-gilroy-medium text-white">To: </span><em className="text-[#FDA14A] text-base font-gilroy-medium not-italic">{item.transferto}</em>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </div>
                        ))}
                    </tbody>
                </table>
            </div>
    );
};

export default TransectionHistoryTable;