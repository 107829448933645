export const SettingData = [
    {
        id: '1',
        title: "OpenSea Link",
        status: true
    },
    {
        id: '2',
        title: "Value/Price (aggregated for the wallet)",
        status: true
    },
    {
        id: '3',
        title: "Current Floor",
        status: false
    },
    {
        id: '4',
        title: "1D Floor",
        status: false
    },
    {
        id: '5',
        title: "1D Floor Delta",
        status: false
    },
    {
        id: '6',
        title: "1D Floor Delta %",
        status: false
    },
    {
        id: '7',
        title: "7D Floor",
        status: false
    },
    {
        id: '8',
        title: "7D Floor Delta",
        status: false
    },
    {
        id: '9',
        title: "7D Floor Delta %",
        status: false
    },

    {
        id: '10',
        title: "30D Floor",
        status: false
    },
    {
        id: '11',
        title: "30D Floor Delta",
        status: false
    },
    {
        id: '12',
        title: "30D Floor Delta %",
        status: false
    },

    {
        id: '13',
        title: "90D Floor",
        status: false
    },
    {
        id: '14',
        title: "90D Floor Delta",
        status: false
    },
    {
        id: '15',
        title: "90D Floor Delta %",
        status: false
    },

    {
        id: '16',
        title: "1D Volume",
        status: false
    },
    {
        id: '17',
        title: "7D Volume",
        status: false
    },
    {
        id: '18',
        title: "30D Volume",
        status: false
    },
    {
        id: '19',
        title: "90D Volume",
        status: false
    },


    {
        id: '20',
        title: "Floor Price (OpenSea)",
        status: true
    },

    {
        id: '21',
        title: "Buy Out (OpenSea)",
        status: false
    },
    {
        id: '22',
        title: "Quantity (Owned in wallet)",
        status: true
    },
    {
        id: '23',
        title: "Quantity (Currently Listed)",
        status: false
    },
    {
        id: '24',
        title: "Aggregated Value",
        status: false
    },
    
]