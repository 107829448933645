// CollectionTierHook.js

import { useReducer, useState } from "react";

interface CollectionTier {
  FP: null | any;
  FM: string | null | any;
  SET: {
    Price: string;
    Token: string;
  };
  TF: any[];
}

interface SelectedTF {
  type: string;
  value: string;
  errors: { type: ""; value: "" };
}

interface CollectionTierError {
  tokenId: string;
  FM: string;
  SET: {
    Price: string;
    Token: string;
  };
}

interface State {
  tokenId: string;
  collectionTier: CollectionTier;
  traitOptions: SelectedTF[];
  traits: SelectedTF[];
  collectionTierError: CollectionTierError;
}

type Action =
  | { type: "UPDATE_COLLECTION_TIER"; payload: CollectionTier }
  | { type: "UPDATE_TRAIT_OPTIONS"; payload: SelectedTF[] }
  | { type: "UPDATE_TRAITS"; payload: SelectedTF[] }
  | { type: "UPDATE_COLLECTION_TIER_ERROR"; payload: CollectionTierError }
  | { type: "UPDATE_TOKEN_ID"; payload: string };

const initialState: State = {
  tokenId: "",
  traitOptions: [
    {
      type: "",
      value: "",
      errors: { type: "", value: "" },
    },
  ],
  traits: [
    {
      type: "",
      value: "",
      errors: { type: "", value: "" },
    },
  ],
  collectionTierError: {
    FM: "",
    SET: {
      Price: "",
      Token: "",
    },
    tokenId: "",
  },
  collectionTier: {
    FP: null,
    FM: "",
    SET: {
      Price: "",
      Token: "",
    },
    TF: [],
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "UPDATE_COLLECTION_TIER":
      return { ...state, collectionTier: action.payload };
    case "UPDATE_TRAIT_OPTIONS":
      return { ...state, traitOptions: action.payload };
    case "UPDATE_TRAITS":
      return { ...state, traits: action.payload };
    case "UPDATE_COLLECTION_TIER_ERROR":
      return { ...state, collectionTierError: action.payload };
    case "UPDATE_TOKEN_ID":
      return { ...state, tokenId: action.payload };
    default:
      return state;
  }
};

export const useCollectionTier = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pricingTab, setPricingTab] = useState("FP");

  /**
   * Handles the selection of pricing tabs.
   * Updates the state with the selected tab.
   * @param {string} tab - The identifier of the selected tab.
   * @returns {void}
   */
  function handlePricingTabs(tab: string): void {
    // handleResetCollectionTier(tab);

    setPricingTab(tab);
  }

  /**
   * Handles changes in the tokenId state.
   * Updates the tokenId with the provided value
   * and dispatches an action to reflect the changes in the state.
   *
   * @param {any} value - The new value representing the updated tokenId .
   * @returns {void}
   */
  const handleTokenIdChange = (value: string): void => {
    dispatch({
      type: "UPDATE_TOKEN_ID",
      payload: value,
    });
  };
  /**
   * Handles changes in the collection tier state.
   * Updates the specified key with the provided value, creating a new collection tier object,
   * and dispatches an action to update the collection tier in the state.
   *
   * @param {string} key - The key to be updated in the collection tier state.
   * @param {any} value - The new value to be set for the specified key.
   * @returns {void}
   */
  const handleCollectionChange = (key: string, value: any): void => {
    const updatedCollectionTier = { ...state.collectionTier, [key]: value };
    dispatch({
      type: "UPDATE_COLLECTION_TIER",
      payload: updatedCollectionTier,
    });
  };
  /**
   * Handles changes in the trait options state.
   * Updates the trait options with the provided value
   * and dispatches an action to reflect the changes in the state.
   *
   * @param {any} value - The new value representing the updated trait options.
   * @returns {void}
   */
  const handleTraitOptionsChange = (value: any): void => {
    dispatch({
      type: "UPDATE_TRAIT_OPTIONS",
      payload: value,
    });
  };

  /**
   * Handles changes in the collection tier set property.
   * Updates the specified property with the provided value
   * in the collection tier set, and dispatches an action to reflect the changes
   * in the overall collection tier state.
   *
   * @param {string} property - The property in the collection tier set to be updated.
   * @param {string} value - The new value to be set for the specified property.
   * @returns {void}
   */
  const handleCollectionSetChange = (property: string, value: string): void => {
    //update collection tier set

    if (property === "Price") {
      const re = /^\d*\.?\d*$/;
      // if value is not blank, then test the regex
      if (value === "" || re.test(value)) {
        let updatedCollectionTierSet = {
          ...state.collectionTier.SET,
          Price: value,
        };
        //update collection tier
        const updatedCollectionTier = {
          ...state.collectionTier,
          SET: updatedCollectionTierSet,
        };
        dispatch({
          type: "UPDATE_COLLECTION_TIER",
          payload: updatedCollectionTier,
        });
      }
      return;
    }

    let updatedCollectionTierSet = {
      ...state.collectionTier.SET,
      [property]: value,
    };
    //update collection tier
    const updatedCollectionTier = {
      ...state.collectionTier,
      SET: updatedCollectionTierSet,
    };
    dispatch({
      type: "UPDATE_COLLECTION_TIER",
      payload: updatedCollectionTier,
    });
  };

  /**
   * Handles changes in the "type" property of a trait option within the state.
   * Updates the "type" property at the specified index with the provided value,
   * resets the "value" property to null, clears the corresponding error, and
   * dispatches an action to reflect the changes in the trait options state.
   *
   * @param {number} index - The index of the trait option to be updated.
   * @param {string} value - The new value to be set for the "type" property.
   * @returns {void}
   */
  const handleTFTypeChange = (index: number, value: string): void => {
    const updatedTF: any = [...state.traitOptions];
    updatedTF[index] = {
      type: value,
      value: null,
      errors: { ...state.traitOptions[index].errors, type: "" },
    };
    handleTraitOptionsChange(updatedTF);
  };

  /**
   * Handles changes in the "value" property of a trait option within the state.
   * Updates the "value" property at the specified index with the provided value,
   * clears the corresponding error, and dispatches an action to reflect the changes
   * in the trait options state.
   *
   * @param {number} index - The index of the trait option to be updated.
   * @param {string} value - The new value to be set for the "value" property.
   * @returns {void}
   */
  const handleTFValueChange = (index: number, value: string): void => {
    const updatedTF: any = [...state.traitOptions];
    updatedTF[index] = {
      ...updatedTF[index],
      value: value,
      errors: { ...state.traitOptions[index].errors, value: "" },
    };
    handleTraitOptionsChange(updatedTF);
  };

  /**
   * Validates the traitOptions array by checking each element for errors.
   * Updates the state with the validated options, including error messages.
   * @returns {boolean} True if all options are valid, false if any option has errors.
   */
  const validateTraitOptions = () => {
    // Validation logic for each element in traitOptions

    const updatedTraitOptions = state.traitOptions.map((option) => {
      let errors = { type: "", value: "" };

      // Your validation logic here
      if (!option.type) {
        errors = { ...errors, type: "Type is required" };
      }
      if (!option.value) {
        errors = { ...errors, value: "Value is required" };
      }

      return { ...option, errors };
    });

    handleTraitOptionsChange(updatedTraitOptions);
    // handleResetCollectionTier(pricingTab);
    // Check if any errors exist
    const hasErrors = updatedTraitOptions.some((option) =>
      Object.values(option.errors).some(Boolean)
    );

    return hasErrors;
  };

  /**
   * Recursively checks an object for the presence of "required" values in its properties.
   *
   * @param {Record<string, any>} errors - The object to be checked for "required" values.
   * @returns {boolean} - True if any property has the value "required", false otherwise.
   */
  function hasRequiredError(errors: Record<string, any>): boolean {
    // Iterate through each property of the object
    for (const key in errors) {
      // Check if the property is a direct property of the object (not inherited)
      if (errors.hasOwnProperty(key)) {
        // Check if the value of the property is "required"
        if (errors[key] === "required") {
          return true; // Found a field with "required" value
        }
        // Check if the value of the property is an object
        if (typeof errors[key] === "object") {
          // If the value is an object, recursively check its properties
          if (hasRequiredError(errors[key])) {
            return true; // Found a field with "required" value in nested object
          }
        }
      }
    }
    return false; // No field with "required" value found in the object
  }

  /**
   * Validates the token id by checking each element for errors.
   * Updates the state with the validated options, including error messages.
   */
  const validateTokenId = (): any => {
    let tokenId: any = state.tokenId;

    let errors = {
      FM: "",
      SET: {
        Price: "",
        Token: "",
      },
      tokenId: "",
    };

    if (!tokenId) {
      errors = {
        ...errors,
        tokenId: `required`,
      };
    }

    dispatch({
      type: "UPDATE_COLLECTION_TIER_ERROR",
      payload: errors,
    });
    return errors;
  };

  /**
   * Validates the collection tier object by checking each element for errors.
   * Updates the state with the validated options, including error messages.
   */
  const validateCollectionTier = (option = pricingTab): any => {
    let collectionTier: any = state.collectionTier;
    let tokenId: any = state.tokenId;

    let errors = {
      FM: "",
      SET: {
        Price: "",
        Token: "",
      },
      tokenId: "",
    };

    if (props.activeTab === "Asset_Tier" && !tokenId) {
      errors = {
        ...errors,
        tokenId: `required`,
      };
    }
    if (option === "SET") {
      if (!collectionTier[option].Price) {
        errors = {
          ...errors,
          SET: { Price: "required", Token: "" },
        };
      } else if (!collectionTier[option].Token) {
        errors = {
          ...errors,
          SET: { Token: "required", Price: "" },
        };
      }
    } else {
      if (!collectionTier[option] && option !== "FP") {
        errors = {
          ...errors,
          [option]: `required`,
        };
      }
    }

    dispatch({
      type: "UPDATE_COLLECTION_TIER_ERROR",
      payload: errors,
    });
    return errors;
  };

  /**
   * Resets the collection tier state based on the currently selected pricing tab.
   * Updates the state with default values for the selected tab,
   * including resetting trait options for "FP", "FM", and "SET" tabs.
   * For the "TF" tab, creates an array of trait options based on the existing types.
   *
   * @returns {void}
   */
  function handleResetCollectionTier(pricingTab: string): void {
    let collectionTier: any = {
      FP: "",
      FM: "",
      SET: {
        Price: "",
        Token: "",
      },
      TF: [],
    };
    let initialTraitOptions: any = [
      {
        type: "",
        value: "",
        errors: {
          type: "",
          value: "",
        },
      },
    ];

    switch (pricingTab) {
      case "FP":
        collectionTier.FP = state.collectionTier.FP;
        dispatch({
          type: "UPDATE_TRAIT_OPTIONS",
          payload: initialTraitOptions,
        });
        break;
      case "FM":
        collectionTier.FM = state.collectionTier.FM;
        dispatch({
          type: "UPDATE_TRAIT_OPTIONS",
          payload: initialTraitOptions,
        });
        break;
      case "SET":
        collectionTier.SET = state.collectionTier.SET;
        dispatch({
          type: "UPDATE_TRAIT_OPTIONS",
          payload: initialTraitOptions,
        });
        break;

      default:
        break;
    }

    dispatch({
      type: "UPDATE_COLLECTION_TIER",
      payload: collectionTier,
    });
  }

  /**
   * Handles the removal of a trait option from the state.
   * Removes the trait option at the specified index,
   * updates the trait options, and dispatches an action to reflect the changes in the state.
   *
   * @param {number} index - The index of the trait option to be removed.
   * @returns {void}
   */
  const handleRemoveTraitOptions = (index: number): void => {
    const updatedTrait = [...state.traitOptions];
    updatedTrait.splice(index, 1);
    handleTraitOptionsChange(updatedTrait);
  };
  function onAddTraitOptions() {
    handleTraitOptionsChange([
      ...state.traitOptions,
      { type: null, value: null, errors: { type: "", value: "" } },
    ]);
  }

  /**
   * Handles changes in the traits state.
   * Updates the traits with the provided value
   * and dispatches an action to reflect the changes in the state.
   *
   * @param {any} value - The new value representing the updated traits.
   * @returns {void}
   */
  const handleTraitsChange = (value: any): void => {
    dispatch({
      type: "UPDATE_TRAITS",
      payload: value,
    });
  };

  /**
   * Handles changes in the "type" property of a traits within the state.
   * Updates the "type" property at the specified index with the provided value,
   * resets the "value" property to null, clears the corresponding error, and
   * dispatches an action to reflect the changes in the traits state.
   *
   * @param {number} index - The index of the trait option to be updated.
   * @param {string} value - The new value to be set for the "type" property.
   * @returns {void}
   */
  const handleTraitsTypeChange = (index: number, value: string): void => {
    const updatedTF: any = [...state.traits];
    updatedTF[index] = {
      type: value,
      value: null,
      errors: { ...state.traits[index].errors, type: "" },
    };
    handleTraitsChange(updatedTF);
  };

  /**
   * Handles changes in the "value" property of a traits within the state.
   * Updates the "value" property at the specified index with the provided value,
   * resets the "value" property to null, clears the corresponding error, and
   * dispatches an action to reflect the changes in the traits state.
   *
   * @param {number} index - The index of the trait option to be updated.
   * @param {string} value - The new value to be set for the "value" property.
   * @returns {void}
   */

  const handleTraitsValueChange = (index: number, value: string): void => {
    const updatedTF: any = [...state.traits];
    updatedTF[index] = {
      ...updatedTF[index],
      value: value,
      errors: { ...state.traits[index].errors, value: "" },
    };
    handleTraitsChange(updatedTF);
  };

  /**
   * Validates the traits array by checking each element for errors.
   * Updates the state with the validated options, including error messages.
   * @returns {boolean} True if all options are valid, false if any option has errors.
   */
  const validateTraits = () => {
    // Validation logic for each element in traits
    const traits = state.traits.map((option) => {
      let errors = { type: "", value: "" };

      // Your validation logic here
      if (!option.type) {
        errors = { ...errors, type: "Type is required" };
      }
      if (!option.value) {
        errors = { ...errors, value: "Value is required" };
      }

      return { ...option, errors };
    });

    handleTraitsChange(traits);
    // handleResetCollectionTier(pricingTab);
    // Check if any errors exist
    const hasErrors = traits.some((option) =>
      Object.values(option.errors).some(Boolean)
    );

    return hasErrors;
  };

  /**
   * Handles the removal of a trait from the state.
   * Removes the trait at the specified index,
   * updates the traits, and dispatches an action to reflect the changes in the state.
   *
   * @param {number} index - The index of the trait to be removed.
   * @returns {void}
   */
  const handleRemoveTraits = (index: number): void => {
    const updatedTrait = [...state.traits];
    updatedTrait.splice(index, 1);
    handleTraitsChange(updatedTrait);
  };
  function onAddTraits() {
    handleTraitsChange([
      ...state.traits,
      { type: null, value: null, errors: { type: "", value: "" } },
    ]);
  }

  return {
    state,
    pricingTab,
    handlePricingTabs,
    handleCollectionChange,
    handleResetCollectionTier,
    validateCollectionTier,
    // trait options
    validateTraitOptions,
    handleTFValueChange,
    handleTFTypeChange,
    handleCollectionSetChange,
    handleRemoveTraitOptions,
    onAddTraitOptions,
    handleTokenIdChange,
    hasRequiredError,
    validateTokenId,
    dispatch,
    // traits
    onAddTraits,
    handleRemoveTraits,
    handleTraitsTypeChange,
    handleTraitsValueChange,
    validateTraits,
  };
};
