import Modal from './Modal';
import TransectionHistoryTable from './TransectionHistoryTable';

type Props={
    setIsOpen?:any,
    isOpen?: boolean,
    className?: any,

}

const TransactionHistoryModal = ({
    setIsOpen, isOpen, className
}:Props) => {
    return (
        <Modal className="w-full" title="Transaction History" mainclassName="!max-w-[1500px] w-full" setIsOpen={setIsOpen} isOpen={isOpen}>
            <div className="w-full max-h-[750px] overflow-auto rt-Scrollnone">
                <div className="w-full">
                    <h4 className="text-white font-gilroy font-semibold mb-3">Wallet Address: <span className="text-[#FDA14A]">0x952...5108</span></h4>
                    <TransectionHistoryTable/>
                </div>
            </div>
        </Modal>
    );
};

export default TransactionHistoryModal;