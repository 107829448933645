import {
  createBrowserRouter
} from "react-router-dom";
import PageLayout from "../components/PageLayout";
import { routes } from "./routes";
import Home from "../pages";
import Collections from "../pages/collections";

export const browserRouter = createBrowserRouter([
    {
      path: routes.HOME,
      element: <PageLayout />,
      
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: routes.COLLECTIONS,
          element: <Collections />
        },
        { path: "*", element: <p>404</p> },
      ],
    },
  ]);